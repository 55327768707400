import { Percentage } from '~/extensions/packages/percentage/percentage';
import { Entity } from '~/legacy-ui/packages/lock/model/Entity';
import { ApiResult } from '~/wm/packages/api/packages/api-result/callApiOrError';
import ProgressResponse from '../../model/ProgressResponse';

type ProgressState = {
  [progressKey: string]: ProgressData;
};

export const progressInitialState: ProgressState = {};

export type ProgressPayload = {
  /**
   * Short label representing the action being performed
   */
  progressLabel: string;

  /**
   * Optionally lock entities while in progress
   */
  entitiesToLock?: Entity[];

  /**
   * Function to, given a context, retrieve a progress state
   */
  fetchProgress: () => Promise<ApiResult<ProgressResponse>>;

  /**
   * Action to take on completion
   */
  onCompletion?: (() => void) | 'reload-page';
};

export type ProgressData = {
  percentage: Percentage;
  message: string;
};

export default ProgressState;

import { css } from '@emotion/react';
import * as React from 'react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';

export type DividerLineType = 'solid' | 'dotted' | 'dashed';
export type DividerProps = { heightRem?: number; lineType?: DividerLineType } & Styleable;

const Divider = ({ heightRem = 0.125, lineType = 'solid', className }: DividerProps) => (
  <hr
    className={className}
    css={css`
      margin: 0;
      width: 100%;
      border-top: ${heightRem}rem ${lineType} ${colorToCode('dark-900-12')};
    `}
  />
);

export default Divider;

import { ProgressPayload } from '../../model/ProgressState';
import { ProgressActionBuilder } from '../progressActions';

export type ProgressStatusKey = 'Progress/Status';
export const progressStatusKey: ProgressStatusKey = 'Progress/Status';

export type ProgressStatus = ProgressActionBuilder<'Progress/Status', ProgressStatusPayload>;

export type ProgressStatusPayload = ProgressPayload;

const progressStatusAction = (payload: ProgressStatusPayload): ProgressStatus => ({
  type: 'Progress/Status',
  payload,
});

export default progressStatusAction;

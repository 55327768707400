import { ProgressData } from '../../model/ProgressState';
import { ProgressActionBuilder } from '../progressActions';

export type ProgressReceivedKey = 'Progress/Received';
export const progressReceivedKey: ProgressReceivedKey = 'Progress/Received';

export type ProgressReceivedPayload = {
  progressKey: string;
  progressLabel: string;
  progress: ProgressData;
};

export type ProgressReceived = ProgressActionBuilder<'Progress/Received', ProgressReceivedPayload>;

const progressReceivedAction = (payload: ProgressReceivedPayload): ProgressReceived => ({
  type: 'Progress/Received',
  payload,
});

export default progressReceivedAction;

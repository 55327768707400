import React, { createContext } from 'react';

export type ScalePadOriginContextType = {
  // Avoiding the name `origin` to avoid competing with the homonymous global variable
  scalePadOrigin: string;
};

const ScalePadOriginContext = createContext<ScalePadOriginContextType>({
  scalePadOrigin: '',
});

export const ScalePadOriginProvider: React.FunctionComponent<{
  scalePadOrigin: string;
  children: React.ReactNode;
}> = ({ scalePadOrigin, children }) => (
  <ScalePadOriginContext.Provider value={{ scalePadOrigin }}>{children}</ScalePadOriginContext.Provider>
);

export default ScalePadOriginContext;

import { css } from '@emotion/react';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import { downstreamLocationToUpstreamUrl, proxyDefinitions, upstreamUrlToDownstreamPath } from '~/scalepad/packages/proxy/proxyDefinitions';
import usePageLoadingIndicator from '~/scalepad/packages/page-loading-indicator/usePageLoadingIndicator';

const sessionKeyForHost = (host: string) => `scalepad_iframe_${host}`;
/**
 * When we go back to a product, our URL tracking can get out of sync.
 * We want to ensure the URL matches when re-entering an active session.
 */
export const getLatestUpstreamUrlForHost = (host: string) => sessionStorage.getItem(sessionKeyForHost(host));
const setLatestUpstreamUrlForHost = (host: string, upstreamUrl: string) => {
  sessionStorage.setItem(sessionKeyForHost(host), upstreamUrl);
};

const IframeRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = usePageLoadingIndicator();

  const upstreamUrl = downstreamLocationToUpstreamUrl(location);
  const upstreamUrlObj = useMemo(() => new URL(upstreamUrl), [upstreamUrl]);
  const upstreamUrlObjRef = useRef(upstreamUrlObj);
  upstreamUrlObjRef.current = upstreamUrlObj;

  const processIframeNavigation = useCallback(
    ({ iframeHost, url, replace }: { iframeHost: string; url: string; replace: boolean }) => {
      const path = upstreamUrlToDownstreamPath(url);

      // Update our cache to allow us to pick up inactive sessions
      setLatestUpstreamUrlForHost(iframeHost, url);

      if (upstreamUrlObj.host !== iframeHost) {
        // A background iframe cannot take over the active product iframe.
        return;
      }

      navigate(path, { replace });
    },
    [navigate, upstreamUrlObj.host],
  );

  /**
   * Handle messages from the iframe
   * @param event check the origin and action of the message
   */
  const handleIframeMessage = useCallback(
    (event: { origin: string; data: { action: string; url: string; replace: boolean } }) => {
      if (typeof event.data.action === 'undefined') {
        // This is a message from a different source.
        return;
      }

      // SECURITY WARNING — For XSS protection, we must ensure not to
      // allow the iframe to impact the host to do arbitrary actions.
      // If the iframe is compromised, it should not infect the host.
      const iframeHost = new URL(event.origin).host;
      if (iframeHost !== window.location.host && !proxyDefinitions.map(def => def.host).includes(iframeHost)) {
        // Unrecognized host - no op
        throw new Error('Could not recognize iframe host: ' + iframeHost);
      }

      switch (event.data.action) {
        case 'PAGE_LOAD':
          // Loading is complete
          setLoading(false);
          if (event.data.url === window.location.href) {
            // No change in URL.
            return;
          }
          // This allows us to react to traditional page loads in the iframe
          processIframeNavigation({
            iframeHost,
            url: event.data.url,
            replace: event.data.replace,
          });
          break;
        case 'PAGE_UNLOAD':
          setLoading(true);
          if (new URL(event.data.url).host !== iframeHost) {
            // The iframe wants to navigate to an external URL.
            // That means we won't keep things in the frame.
            window.location.href = event.data.url;
          }
          break;
        case 'NAVIGATE_EXTERNAL':
          if (new URL(event.data.url).host !== iframeHost) {
            // The iframe wants to navigate to an external URL.
            // That means we won't keep things in the frame.
            window.location.href = event.data.url;
          }
          break;
        case 'CLIENT_SIDE_NAV':
          // This allows us to follow client-side routing on the parent
          processIframeNavigation({
            iframeHost,
            url: event.data.url,
            replace: event.data.replace,
          });
          break;
        default:
          // We shouldn't throw here for forwards compatibility
          // eslint-disable-next-line no-console
          console.error('Received unrecognized action from iframe: ' + event.data.action);
          break;
      }
    },
    [processIframeNavigation, setLoading],
  );

  /**
   * Add event listener for messages from the iframe
   */
  useEffect(() => {
    // If reattaching the listener happens too much
    // we could instead track a mutable ref and use
    // that in the event listener. This is just simpler.
    window.addEventListener('message', handleIframeMessage);
    return () => window.removeEventListener('message', handleIframeMessage);
  }, [handleIframeMessage]);

  useEffect(() => {
    const handleBackForwardButtons = () => {
      setFromPopstate(true);
    };
    window.addEventListener('popstate', handleBackForwardButtons);
    return () => window.removeEventListener('popstate', handleBackForwardButtons);
  }, []);

  // Here, we're following an interesting model.
  // 1. We lazily-load iframes per product.
  //    This allows you to swap between products without reloading.
  // 2. We ensure iframe nav events don't rerender the iframe.
  //    This optimization avoids redundancy — changes to URLs are handled within the iframes!
  const lazilyLoadedUpstreamUrlsByHost = useRef(new Map<string, string>());

  // This lazily sets hosts up when we discover them.
  lazilyLoadedUpstreamUrlsByHost.current.set(upstreamUrlObj.host, upstreamUrl);

  const [fromPopstate, setFromPopstate] = useState(false);
  useEffect(() => {
    if (fromPopstate) {
      setFromPopstate(false);
    }
  }, [fromPopstate]);

  // Typically, we absorb changes in the iframe path.
  // This is because iframe navigation happens primarily within the iframe,
  // so it's redundant to reload the iframe on a nav change.
  const reloadIframeSrc =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (location.state?.forceFrameReload ?? false) ||
    // Always reload on popstate events (back/forward button press)
    fromPopstate;

  return (
    <>
      {[...lazilyLoadedUpstreamUrlsByHost.current.entries()].map(([host, url]) => (
        <IframeSecure
          key={host}
          src={url}
          reloadSrc={reloadIframeSrc}
          hidden={host !== upstreamUrlObj.host}
        />
      ))}
    </>
  );
};

/**
 * A wrapper over iframe that enables certain sandboxing features.
 * @param initialSrc The initial src of the iframe. We don't reload on src changes.
 * @param hidden Whether the iframe is displayed or not.
 * @constructor
 */
const IframeSecure: FunctionComponent<{
  src: string;
  hidden?: boolean;
  reloadSrc?: boolean;
}> = ({ src: srcProp, hidden = false, reloadSrc }) => {
  // This ensures the iframe doesn't reload on src changes.
  const [src, setSrc] = useState(srcProp);
  const [key, setKey] = useState(srcProp);

  const { setLoading } = usePageLoadingIndicator();

  useEffect(() => {
    if (reloadSrc) {
      // reloadSrc is true — we update!
      setSrc(srcProp);
      if (src === srcProp) {
        // If the src hasn't changed, we need to force a refresh
        // with dirtier tactics. Update the key prop to trigger
        // remount.
        setKey(key => key + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadSrc, srcProp]);

  useEffect(() => {
    // src changed, begin loading indicator
    setLoading(true);

    // Add a redundant fallback timeout just to avoid a stuck loading indicator
    // (in the case a product's js fails to send the PAGE_LOAD message for some reason)
    setTimeout(() => {
      setLoading(false);
    }, 1300);
  }, [setLoading, src]);

  return (
    <iframe
      key={key}
      css={css`
        ${hidden ? 'display: none;' : ''}
        margin: -1.875rem 0 -1.875rem -1.875rem;
        width: calc(100% + 3.75rem);
        height: 100%;
        flex-grow: 1;
        overflow-x: hidden;
        border: none;
        @media screen and (max-width: ${mobileBreakpointRem}rem) {
          width: 100vw;
          height: calc(100vh - 120px);
          margin: -1.25rem 0 -1.25rem -1.25rem;
        }
      `}
      src={src}
      sandbox={[
        'allow-forms', // Allows form submission
        'allow-modals', // Allows to open modal windows
        'allow-orientation-lock', // Allows to lock the screen orientation
        'allow-pointer-lock', // Allows to use the Pointer Lock API
        'allow-popups', // Allows popups
        'allow-popups-to-escape-sandbox', // Allows popups to open new windows without inheriting the sandboxing
        'allow-presentation', // Allows to start a presentation session
        'allow-scripts', // Allows to run scripts

        // Not having this would disable important APIs like localStorage.
        // Browser's already isolate by origin, so this is safe.
        'allow-same-origin', // Allows the iframe content to be treated as being from the same origin

        'allow-downloads', // Allows products to trigger download of reports, etc

        // — Disabled for security reasons —
        // These must be done by postMessage API if needed (controlled by us).
        // 'allow-top-navigation',  // Allows the iframe content to navigate its top-level browsing context
        // 'allow-top-navigation-by-user-activation', // Allows the iframe content to navigate its top-level browsing context, but only if initiated by user
      ].join(' ')}
    />
  );
};

export default IframeRoute;

import { Action } from '~/extensions/packages/state/abstractActions';
import { ApiErrorType } from '../model/getApiErrorDefinition';

export enum ErrorActionType {
  ApiError = 'Api/Error',
}

export interface ApiErrorAction
  extends Action<
    ErrorActionType.ApiError,
    {
      apiErrorType: ApiErrorType;
    }
  > {}

const apiErrorAction = (apiErrorType: ApiErrorType): ApiErrorAction => ({
  type: ErrorActionType.ApiError,
  payload: { apiErrorType },
});

export default apiErrorAction;

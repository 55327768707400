import { put, takeEvery } from 'redux-saga/effects';
import { showAlertAction } from '~/legacy-ui/packages/alert/state/action/alertActions';
import GlobalError from '~/neo-ui/packages/error/model/GlobalError';
import getApiErrorDefinition from '../model/getApiErrorDefinition';
import { ApiErrorAction, ErrorActionType } from '../state/apiErrorAction';

export function* apiErrorWorker(action: ApiErrorAction) {
  const errorPayload: GlobalError = getApiErrorDefinition(action.payload.apiErrorType);

  yield put(
    showAlertAction({
      heading: errorPayload.title,
      children: errorPayload.message,
      theme: 'danger',
    }),
  );
}

function* apiErrorWatcher() {
  yield takeEvery(ErrorActionType.ApiError, apiErrorWorker);
}

export default apiErrorWatcher;

import { useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import Box from '~/neo-ui/packages/box/Box';
import Divider from '~/neo-ui/packages/divider/Divider';
import ProductLinks from '~/wm/packages/sp-account/packages/app-switcher/ProductLinks';
import useClickAwayListener from './useClickAwayListener';
import useTriggerRegistrar from './useTriggerRegistrar';
import useProductList from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/useProductList';
import useScalePadUid from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/useScalePadUid';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import AppSwitcherImage from '~/wm/packages/sp-account/packages/app-switcher/AppSwitcherImage';

export interface AppSwitcherProps {
  manageAccountUrl: string;
  scalePadCommunityUrl: string;
  isIframePrototype?: boolean;
}

const AppSwitcher = ({ manageAccountUrl, scalePadCommunityUrl, isIframePrototype }: AppSwitcherProps) => {
  const [isHidden, setIsHidden] = useState(true);
  const { identity } = useScalePadUid();
  const isSignedIn = !!identity;

  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const toggleHidden = (hidden?: boolean) => setIsHidden(previous => (typeof hidden === 'boolean' ? hidden : !previous));

  const [triggerElement, setTriggerElement] = useState<HTMLButtonElement | null>(null);
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  const { products } = useProductList();

  const { styles, attributes, update } = usePopper(triggerElement, containerElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 12],
        },
      },
    ],
  });

  const triggerClickHandler = useCallback(async () => {
    toggleHidden();
    await update?.();
  }, [update]);

  const clickAwayHandler = useCallback(async () => {
    toggleHidden(true);
    await update?.();
  }, [update]);

  useTriggerRegistrar(triggerClickHandler, setTriggerElement);
  useClickAwayListener(clickAwayHandler, triggerElement, containerElement);

  const AppSwitcherLabel = ({ children }: { children: React.ReactNode }) => (
    <li
      css={{
        color: '#9596A7',
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '9px',
        letterSpacing: '1.4px',
        textTransform: 'uppercase',
      }}
    >
      {children}
    </li>
  );

  return (
    <div
      ref={setContainerElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <Box
        css={{
          borderRadius: '4px',
          width: '380px',
          height: isHidden ? 0 : 'auto',
          transition: 'transform 200ms, visibility 200ms, opacity 200ms',
          visibility: isHidden ? 'hidden' : 'visible',
          transform: isHidden ? 'scale(0)' : 'scale(1)',
          transformOrigin: 'top left',
          opacity: isHidden ? 0 : 1,
          boxShadow: `0 12px 24px 0 rgba(0,0,0,0.20)`,
        }}
      >
        <ul
          css={{
            padding: '12px 0',
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'column',
            ['& > li:not(.product-link)']: {
              paddingBlock: '12px',
              paddingInline: '24px',
            },
          }}
        >
          <>
            {!products && <AppSwitcherLabel>Failed to Load ScalePad Products</AppSwitcherLabel>}
            {products &&
              (isSignedIn ? (
                <>
                  <AppSwitcherLabel>Your Products</AppSwitcherLabel>
                  <ProductLinks
                    products={products.ownedProducts}
                    isOwned={true}
                    onProductSelected={() => toggleHidden(true)}
                    isIframePrototype={isIframePrototype}
                  />
                  {products.unownedProducts.length > 0 && (
                    <>
                      <Divider
                        css={{
                          margin: '4px 8px 4px 8px',
                          width: 'calc(100% - 16px)',
                          border: 'none',
                          height: '1px',
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        }}
                      />
                      <AppSwitcherLabel>More from ScalePad</AppSwitcherLabel>
                      <ProductLinks
                        products={products.unownedProducts}
                        isOwned={false}
                        onProductSelected={() => toggleHidden(true)}
                        isIframePrototype={isIframePrototype}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <AppSwitcherLabel>Explore our ScalePad Products</AppSwitcherLabel>
                  <ProductLinks
                    products={products.unownedProducts}
                    isOwned={false}
                    onProductSelected={() => toggleHidden(true)}
                    isIframePrototype={isIframePrototype}
                  />
                </>
              ))}
            <>
              <Divider
                css={{
                  margin: '4px 8px 4px 8px',
                  width: 'calc(100% - 16px)',
                  padding: 0,
                  border: 'none',
                  height: '1px',
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                }}
              />
              <li>
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '0.5rem',
                    alignSelf: 'stretch',
                  }}
                >
                  <a
                    href={manageAccountUrl}
                    target="_blank"
                    onClick={() => trackEventProcessor('app_bar_scalepad_account_visited', {})}
                    css={{
                      display: 'flex',
                      width: '100%',
                      color: 'rgb(5, 117, 204)',
                      padding: '8px 0 8px 0',
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      justifyContent: 'space-between',
                      appearance: 'none',
                      borderRadius: '6px',
                      border: 'none',
                      transition: 'all 150ms',
                      ['&:hover']: {
                        backgroundColor: `${colorToCode('primary-100')}`,
                      },
                    }}
                  >
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <AppSwitcherImage
                        src={`/neo-icons/UserProfile.svg?color=${encodeURIComponent(colorToCode('primary-400'))}`}
                        css={{
                          width: '16px',
                          height: '16px',
                        }}
                      />
                      Manage your account
                    </div>
                    <AppSwitcherImage
                      src={`/neo-icons/GoExternal.svg?color=${encodeURIComponent(colorToCode('primary-400'))}`}
                      css={{
                        width: '16px',
                        height: '16px',
                      }}
                    />
                  </a>
                  <a
                    href={scalePadCommunityUrl}
                    target="_blank"
                    css={{
                      display: 'flex',
                      width: '100%',
                      color: 'rgb(5, 117, 204)',
                      padding: '8px 0 8px 0',
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      justifyContent: 'space-between',
                      appearance: 'none',
                      borderRadius: '6px',
                      border: 'none',
                      transition: 'all 150ms',
                      ['&:hover']: {
                        backgroundColor: `${colorToCode('primary-100')}`,
                      },
                    }}
                  >
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <AppSwitcherImage
                        src={'/i/SPcommunity-icon-1024-colour-transparent.png'}
                        css={{
                          width: '16px',
                          height: '16px',
                        }}
                      />
                      ScalePad Community
                    </div>
                    <AppSwitcherImage
                      src={`/neo-icons/GoExternal.svg?color=${encodeURIComponent(colorToCode('primary-400'))}`}
                      css={{
                        width: '16px',
                        height: '16px',
                      }}
                    />
                  </a>
                </div>
              </li>
            </>
          </>
        </ul>
      </Box>
    </div>
  );
};

export default AppSwitcher;

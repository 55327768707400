import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '~/extensions/packages/state/configureStore';
import rootReducer from '~/wm/state/rootReducer';
import rootSaga from '~/wm/io/rootSaga';
import ScalePadTopNav, { topBarHeightPx } from '~/scalepad/ScalePadTopNav';
import { ScalePadOriginProvider } from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/ScalePadOriginContext';
import { ScalePadUidProvider } from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/ScalePadUidContext';
import { ScalePadProductsProvider } from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/ScalePadProductsContext';
import { setApiClientConfig } from '~/client/clientConfig';
import UserflowProvider from '~/scalepad/packages/scalepad-help-center-icon/packages/userflow/UserflowProvider';

export const scalePadOrigin = ((): string => {
  // Origin of wherever this script was loaded from
  const currentScript = document.currentScript as HTMLScriptElement | null;
  if (currentScript) {
    try {
      const url = new URL(currentScript.src);
      return url.origin;
    } catch (err) {}
  }
  // Just in case of any issues, default to prod origin
  return 'https://app.scalepad.com';
})();

// This globally modifies the behavior of API clients to use
// the defined origin.
setApiClientConfig({ origin: scalePadOrigin });

const store = configureStore(rootReducer, rootSaga);

const topBarContainerId = 'scalepad-top-bar-container';

export const showAppBar = () => {
  // Ensures all content adjusts to the app bar being visible
  setAppBarHeightPxCssVariable(topBarHeightPx);

  const topBarContainerElement = document.getElementById(topBarContainerId);
  if (topBarContainerElement) {
    topBarContainerElement.style.visibility = 'visible';
    return;
  }

  const domNode = document.createElement('div');
  domNode.id = topBarContainerId;
  domNode.style.position = 'fixed';
  domNode.style.top = '0';
  domNode.style.width = '100%';
  domNode.style.height = `var(--app-bar-height)`;
  domNode.style.zIndex = '20000';
  domNode.style.fontSize = '16px';
  document.body.prepend(domNode);

  const root = createRoot(domNode);

  root.render(
    <StrictMode>
      <Provider store={store}>
        <UserflowProvider>
          <ScalePadUidProvider>
            <ScalePadProductsProvider>
              <ScalePadOriginProvider scalePadOrigin={scalePadOrigin}>
                <ScalePadTopNav />
              </ScalePadOriginProvider>
            </ScalePadProductsProvider>
          </ScalePadUidProvider>
        </UserflowProvider>
      </Provider>
    </StrictMode>,
  );
};

export const hideAppBar = () => {
  // Ensures all content adjusts to the app bar being hidden
  setAppBarHeightPxCssVariable(0);

  const topBarContainerElement = document.getElementById(topBarContainerId);
  if (!topBarContainerElement) {
    return;
  }

  topBarContainerElement.style.visibility = 'hidden';
};

const setAppBarHeightPxCssVariable = (heightPx: number) => {
  document.documentElement.style.setProperty('--app-bar-height', `${heightPx}px`);
};

import { produce } from 'immer';
import { assertNever } from '~/extensions/packages/types/assertNever';
import { AlertAction, AlertActionType } from '../action/alertActions';
import AlertState, { alertInitialState } from '../model/AlertState';

const alertReducer = (state: AlertState = alertInitialState, action: AlertAction) =>
  produce(state, (draft: AlertState) => {
    switch (action.type) {
      case AlertActionType.ShowAlert:
        draft.alertData = { ...action.payload };
        break;
      case AlertActionType.HideAlert:
        draft.alertData = undefined;
        break;
      default:
        assertNever(action);
        break;
    }
  });

export default alertReducer;

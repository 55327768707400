import { Action } from '~/extensions/packages/state/abstractActions';
import { AlertData } from '../../Alert';

export enum AlertActionType {
  ShowAlert = 'Alert/Show',
  HideAlert = 'Alert/Hide',
}

export type AlertShowAction = Action<AlertActionType.ShowAlert, AlertData>;
export const showAlertAction = (alertData: AlertData): AlertShowAction => ({
  type: AlertActionType.ShowAlert,
  payload: alertData,
});

export type AlertHideAction = Action<AlertActionType.HideAlert>;

export const hideAlertAction = (): AlertHideAction => ({
  type: AlertActionType.HideAlert,
  payload: {},
});

export type AlertAction = AlertShowAction | AlertHideAction;

// Don't import default here, as products have already done so
// And tree shaking does not work across separately gen'd bundles
// And we could duplicate versions of userflow.js
import type { Userflow } from 'userflow.js';
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';

declare global {
  interface Window {
    userflow: Userflow | undefined;
  }
}

type UserflowContextType = Userflow | undefined;
export const UserflowContext = createContext<UserflowContextType>(undefined);

export const useUserflowContext = () => useContext(UserflowContext);

const UserflowProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [userflow, setUserflow] = useState<Userflow | undefined>(window.userflow);

  useEffect(() => {
    const onLoad = () => {
      // Document loaded — will have global vars if added as snippet
      setUserflow(window.userflow);
    };
    document.addEventListener('DOMContentLoaded', onLoad);
    return () => document.removeEventListener('DOMContentLoaded', onLoad);
  }, []);

  useEffect(() => {
    // This handles the case where userflow is added after the document is loaded.
    // Given that we do not control userflow for all products, it is non-trivial
    // for us to detect when it is loaded, so we poll for it for a bit.
    let interval: NodeJS.Timeout | undefined;
    (() => {
      if (userflow) {
        clearInterval(interval);
        return;
      }
      const intervalMs = 100;
      let intervalCount = 0;
      const timeout = 3000;
      interval = setInterval(() => {
        if (window.userflow) {
          setUserflow(window.userflow);
          clearInterval(interval);
          return;
        }

        intervalCount++;
        if (intervalCount * intervalMs >= timeout) {
          clearInterval(interval);
        }
      }, intervalMs);
    })();

    return () => {
      clearInterval(interval);
    };
  }, [userflow]);

  return <UserflowContext.Provider value={userflow}>{children}</UserflowContext.Provider>;
};

export default UserflowProvider;

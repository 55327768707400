import { Action } from '~/extensions/packages/state/abstractActions';
import { AlertModalData } from '../../AlertModal';

export enum ModalActionType {
  ShowModal = 'Modal/Show',
  HideModal = 'Modal/Hide',
}

export type ModalShowAction = Action<ModalActionType.ShowModal, AlertModalData>;
export const showModalAction = (modalData: AlertModalData): ModalShowAction => ({
  type: ModalActionType.ShowModal,
  payload: modalData,
});

export type ModalHideAction = Action<ModalActionType.HideModal>;

export const hideModalAction = (): ModalHideAction => ({
  type: ModalActionType.HideModal,
  payload: {},
});

type ModalAction = ModalShowAction | ModalHideAction;

export default ModalAction;

import { Location } from 'react-router-dom';

let scalePadHost = window.location.host;
export const configureHost = (host: string) => {
  scalePadHost = host;
};

export type ProxyDefinition = {
  // Path key e.g., /lifecycle-insights
  pathKey: string;

  // Upstream host
  host: string;
};

export const proxyDefinitions: ProxyDefinition[] = [
  {
    pathKey: 'lifecycle-insights',
    host: 'app-dev.lifecycleinsights.io',
  },
  {
    pathKey: 'backup-radar',
    host: 'test.backupradar.com',
  },
  {
    pathKey: 'controlmap',
    host: 'scalx.app.ctrlmap.com',
  },
];

export const proxyDefinitionsByDownstreamKey: Record<string, ProxyDefinition> = proxyDefinitions.reduce((acc, proxyDefinition) => {
  acc[proxyDefinition.pathKey] = proxyDefinition;
  return acc;
}, {} as Record<string, ProxyDefinition>);

export const proxyDefinitionsByUpstreamHost: Record<string, ProxyDefinition> = proxyDefinitions.reduce((acc, proxyDefinition) => {
  acc[proxyDefinition.host] = proxyDefinition;
  return acc;
}, {} as Record<string, ProxyDefinition>);

/**
 * Converts a downstream full path to the full upstream URL.
 *
 * Example:
 * - /backup-radar/settings?filter=1 --> https://us.backupradar.com/settings?filter=1
 */
export const downstreamLocationToUpstreamUrl = (downstreamLocation: Location) => {
  const downstreamFullPath = downstreamLocation.pathname + downstreamLocation.search + downstreamLocation.hash;

  return downstreamFullPathToUpstreamUrl(downstreamFullPath);
};

const getPathKeyFromDownstreamPath = (downstreamPath: string) => downstreamPath.split('/')[2];

export const downstreamPathToProxyDefinition = (downstreamPath: string) => {
  const downstreamPathKey = getPathKeyFromDownstreamPath(downstreamPath);
  return proxyDefinitionsByDownstreamKey[downstreamPathKey];
};

const downstreamFullPathToUpstreamUrl = (downstreamFullPath: string) => {
  const downstreamPathKey = getPathKeyFromDownstreamPath(downstreamFullPath);
  const proxyDefinition = proxyDefinitionsByDownstreamKey[downstreamPathKey];

  return proxyDefinition
    ? // Proxy definition exists
      (() => {
        const upstreamPath = downstreamFullPath.replace(`/product/${downstreamPathKey}`, '');

        return `https://${proxyDefinition.host}${upstreamPath}`;
      })()
    : // No proxy — forward along downstream path as URL
      `${window.location.protocol}//${scalePadHost}${downstreamFullPath}`;
};

export const upstreamUrlToProxyDefinition = (upstreamUrlString: string): ProxyDefinition | undefined => {
  const upstreamUrl = new URL(upstreamUrlString);
  const upstreamHost = upstreamUrl.host;

  return proxyDefinitionsByUpstreamHost[upstreamHost];
};

export const upstreamUrlToDownstreamPath = (upstreamUrlString: string) => {
  const upstreamUrl = new URL(upstreamUrlString);
  const upstreamPathFull = upstreamUrl.pathname + upstreamUrl.search + upstreamUrl.hash;

  const proxyDefinition = upstreamUrlToProxyDefinition(upstreamUrlString);

  return proxyDefinition
    ? // Proxy definition exists
      `/product/${proxyDefinition.pathKey}${upstreamPathFull}`
    : // No proxy — forward along upstream path as URL
      upstreamPathFull;
};

export const upstreamUrlToDownstreamUrl = (upstreamUrlString: string) =>
  `${window.location.protocol}//${scalePadHost}${upstreamUrlToDownstreamPath(upstreamUrlString)}`;

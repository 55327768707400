import {
  downstreamPathToProxyDefinition,
  upstreamUrlToDownstreamPath,
  upstreamUrlToProxyDefinition,
} from '~/scalepad/packages/proxy/proxyDefinitions';
import { useNavigate } from 'react-router-dom';
import { getLatestUpstreamUrlForHost } from '~/scalepad/packages/proxy/IframeRoute';
import { ProductDto } from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/ScalePadProductsContext';

const useProductNavigate = () => {
  const navigate = useNavigate();
  const navigateToProduct = (product: ProductDto) => {
    const newProxyDefinition = upstreamUrlToProxyDefinition(product.url);
    const currentProxyDefinition = downstreamPathToProxyDefinition(window.location.pathname);
    const currentUpstreamHost = currentProxyDefinition?.host ?? window.location.host;

    const newUpstreamHost =
      newProxyDefinition?.host ??
      // No proxy definition => LM
      window.location.host;

    // If the host is the same, we ensure the frame reloads, rather than just
    // swapping to the selected product.
    // This covers two use cases:
    // 1. If you're in a product, you can use the product switcher to take
    //    you back to your homepage for that product (intended case).
    // 2. As a nice side benefit, if you're in ScalePad account, hitting LM
    //    will take you to LM. This only happens since LM & SP share the
    //    same host, so it's a bit of a hack.
    const forceFrameReload = newUpstreamHost === currentUpstreamHost;

    // If we have a cached upstream url for this host (product), use it.
    const cachedUpstreamUrlForHost = getLatestUpstreamUrlForHost(newUpstreamHost);

    const upstreamUrl = forceFrameReload
      ? // Semantically reaching product homepage. Forcing the original link.
        product.url
      : // Semantically switching views. Using the cached link if possible.
        cachedUpstreamUrlForHost ?? product.url;

    // Convert the upstream url to the ScalePad equivalent.
    const downstreamPath = upstreamUrlToDownstreamPath(upstreamUrl);
    navigate(downstreamPath, { state: { forceFrameReload } });
  };

  return {
    navigateToProduct,
  };
};

export default useProductNavigate;

import { produce } from 'immer';
import { assertNever } from '~/extensions/packages/types/assertNever';
import { ProgressAction } from '../action/progressActions';
import ProgressState, { progressInitialState } from '../model/ProgressState';

const progressReducer = (state = progressInitialState, action: ProgressAction) =>
  produce(state, (draft: ProgressState) => {
    switch (action.type) {
      case 'Progress/Status':
        break;
      case 'Progress/Requested':
        break;
      case 'Progress/Received':
        draft[action.payload.progressKey] = action.payload.progress;
        break;
      case 'Progress/Stopped':
        delete draft[action.payload.progressKey];
        break;
      default:
        assertNever(action);
        break;
    }
  });

export default progressReducer;

import { ProgressPayload } from '../../model/ProgressState';
import { ProgressActionBuilder } from '../progressActions';

export type ProgressRequestedKey = 'Progress/Requested';
export const progressRequestedKey: ProgressRequestedKey = 'Progress/Requested';

export type ProgressRequested = ProgressActionBuilder<'Progress/Requested', ProgressRequestedPayload>;

export type ProgressRequestedPayload = ProgressPayload;

const progressRequestedAction = (payload: ProgressRequestedPayload): ProgressRequested => ({
  type: 'Progress/Requested',
  payload,
});

export default progressRequestedAction;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ApiServerErrorResponseDto } from '~/wm/packages/api/packages/api-error/model/ApiServerErrorResponseDto';
import apiErrorAction from '~/wm/packages/api/packages/api-error/state/apiErrorAction';
import callApiOrError from '~/wm/packages/api/packages/api-result/callApiOrError';

const useApi = () => {
  const dispatch = useDispatch();

  const callApi = useCallback(
    async <TResponse>(
      apiCall: () => Promise<TResponse>,
      validationErrorHandler?: (fieldErrors: ApiServerErrorResponseDto) => void,
      /**
       * Silence any errors e.g., if this is a non-critical API call
       */
      ignoreError: boolean = false,
      onApiError?: () => void,
    ): Promise<TResponse | undefined> => {
      const apiResult = await callApiOrError(apiCall);

      if (apiResult.type === 'error') {
        // It should sign out user if we are getting session-expired error
        if (apiResult.data.type === 'session-expired') {
          dispatch(apiErrorAction(apiResult.data.type));
          WM.getAjax(
            `${window.location.origin}${WM.signoutRoute}`,
            () => {
              window.location.href = `${window.location.origin}`;
            },
            () => {},
            0,
          );
          return undefined;
        }

        if (typeof onApiError !== 'undefined') {
          onApiError();
        }

        // If set to ignore error: no global error
        if (ignoreError) {
          return undefined;
        }

        // If a validation error handler is specified: no global error
        if (apiResult.data.type === 'validation-error' && typeof validationErrorHandler !== 'undefined') {
          validationErrorHandler(apiResult.data.payload);
        } else {
          dispatch(apiErrorAction(apiResult.data.type));
        }
        return undefined;
      }

      return apiResult.data;
    },
    [dispatch],
  );

  return {
    callApi,
  };
};

export default useApi;

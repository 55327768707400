import { css } from '@emotion/react';
import React from 'react';
import { Percentage } from '~/extensions/packages/percentage/percentage';
import progressAnimation from './progressAnimation';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export interface ProgressBarProps {
  progressPercentage: Percentage;
  theme: 'light' | 'dark';
}

const ProgressBar = ({ progressPercentage, theme }: ProgressBarProps) => {
  const { bgColor, fgColor, hasAnimation }: { bgColor: string; fgColor: string; hasAnimation: boolean } = (() => {
    switch (theme) {
      case 'light':
        return {
          bgColor: colorToCode('dark-900-12'),
          fgColor: colorToCode('dark-100'),
          hasAnimation: true,
        };
      case 'dark':
        return {
          bgColor: colorToCode('light-000-42'),
          fgColor: colorToCode('light-000'),
          hasAnimation: false,
        };
    }
  })();
  return (
    <div
      css={css`
        border-radius: 0.25rem;
        overflow: hidden;
        line-height: 0;
        font-size: 0.75rem;
        background-color: ${colorToCode('dark-900-12')};
        width: 100%;
      `}
    >
      <div
        role={'progressbar'}
        aria-valuenow={progressPercentage.value}
        aria-valuemin={0}
        aria-valuemax={100}
        css={css`
          height: 0.3125rem;
          width: ${progressPercentage.value}%;
          background-color: ${bgColor};
          ${hasAnimation &&
          css`
            ${progressAnimation};
            background-image: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
          `}
          border-radius: 5px;
          background-blend-mode: overlay;
          background-repeat: no-repeat;
          background-size: 4rem 1rem;
          background-color: ${fgColor};
        `}
      />
    </div>
  );
};

export default ProgressBar;

import Color from '~/neo-ui/packages/color/Color.gen';

type Theme = SemanticTheme | LegacyTheme;

export type SemanticTheme = 'primary' | 'secondary' | 'positive' | 'caution' | 'warning' | 'negative' | 'excellent' | 'info';

/**
 * @deprecated These themes are added to support legacy use cases and directly translate to **positive** and **negative**
 */
export type LegacyTheme = 'success' | 'danger';

/**
 * Convert a theme into a semantic theme
 * Converts a legacy theme into it's corresponding semantic theme
 * @param theme
 */
export const removeLegacyTheme: (theme: Theme | undefined) => SemanticTheme | undefined = theme => {
  switch (theme) {
    case 'success':
      return 'positive';
    case 'danger':
      return 'negative';
    default:
      return theme;
  }
};

/**
 * @deprecated Support legacy theme usages where theme was used as a color name
 * i.e. warning -> warning-400
 *
 * Consider using the themeMap from useTheme
 * Convert a theme into it's base color with a given weight
 *
 * Using this method does not guarantee your new color exists
 * @param theme
 * @param weight
 */
export const themeToColor: (theme: Theme | undefined, weight: string) => Color = (theme, weight) => {
  const semanticTheme = removeLegacyTheme(theme);

  return typeof semanticTheme === 'undefined' ? 'light-000' : (`${semanticTheme}-${weight}` as Color);
};

/**
 * @deprecated Support legacy theme usages that decremented or incremented the color based on string name
 * i.e. light6 - 1 = light5 -> light-900 - 1 = light-800
 *
 * Consider using themeMap from useTheme
 * Convert a color into a lighter or darker version in its theme
 *
 * Using this method does not guarantee your new color exists
 * @param color color to adjust
 * @param adjustment amount of adjustment, -1 is one step lighter and 1 is one step darker
 */
export const colorToThemeAdjustedColor: (color: Color, adjustment: number) => Color = (color, adjustment) =>
  `${color.substring(0, color.length - 3)}${Number(color.charAt(color.length - 3)) + adjustment}${color.substring(
    color.length - 2,
  )}` as Color;

/**
 * @deprecated Support legacy color usage that used a color as a theme
 * i.e. warning = warning -> warning-400 = warning
 *
 * Convert a color into its base theme
 *
 * Using this method does not guarantee your new color exists
 * @param color color to convert into its base theme
 */
export const colorToTheme: (color: Color) => Theme = color => color.split('-')[0] as Theme;

export default Theme;

// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.

import axios, { AxiosRequestConfig, Method, AxiosError } from 'axios';
import { sleep } from '~/extensions/packages/sleep/sleep';
import { clientConfig } from '~/client/clientConfig';

const streamSaverPromise = typeof document !== 'undefined'
  // Importing the streamSaver library uses window which crashes in server-side rendering contexts
  ? import('streamsaver')
  : undefined;


//#region ApiCalls


//#region CaseConverters

export function formatRequest(input: any)
{
  const output : any = {};
  let key;
  let updatedKey;
  let value;
  if (input instanceof Array)
  {
    return input.map(function(value)
    {
      if (typeof value === 'object')
      {
        value = formatRequest(value);
      }
      return value;
    });
  }
  else
  {
    for (key in input)
    {
      if (input.hasOwnProperty(key))
      {
        value = input[key];
        if (value instanceof Array || (value !== null && typeof value !== 'undefined' && value.constructor === Object))
        {
          value = formatRequest(value);
        }

        updatedKey = key.includes('-')
          // This is likely not a property but a custom dictionary key (workaround for WM-1555)
          ? key
          : camelToPascal(key);
        output[updatedKey] = value;
      }
    }
  }
  return output;
}

const camelToPascal = (key: string) => (key.charAt(0).toUpperCase() + key.slice(1) || key).toString();
const pascalToCamel = (key: string) => (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();

const formatKey = (key: string) => key.includes('-')
  // This is likely not a property but a custom dictionary key (workaround for WM-1555)
  ? key
  : pascalToCamel(key);

type ValueTransformer = (value: any) => any;

/**
 * It is our convention to use undefined throughout the codebase to reduce
 * confusion and ambiguity between undefined and null.
 *
 * Therefore, we map nulls from the API to undefined for use in Typescript.
 */
const transformNullToUndefined: ValueTransformer = (value) =>
    value === null ? undefined : value;

/**
 * Transformations applied to all json values before passing to the consumer
 */
const valueTransformations: ValueTransformer[]  = [
  transformNullToUndefined
];

const formatValue = <T>(value: T) =>
    // Apply all transformations
    valueTransformations.reduce((value, transform) => transform(value), value);

export function formatResponse(input: any): any
{
  const output: any = {};
  if (input instanceof Array)
  {
    return input.map((value) =>
        typeof value === 'object' && value !== null
            // It's an object or array, recurse
            ? formatResponse(value)
            : formatValue(value));
  }
  else
  {
    for (const key in input)
    {
      if (input.hasOwnProperty(key))
      {
        const value = input[key];

        // Format the key
        const formattedKey = formatKey(key);

        // Format the value
        const formattedValue = typeof value === 'object' && value !== null
          // It's an object or array, recurse
          ? formatResponse(value)
          : formatValue(value);

        // Build output object
        output[formattedKey] = formattedValue;
      }
    }
  }
  return output;
}

//#endregion CaseConverters


const prefix = () => clientConfig.origin + "/api/Subscription"

async function callApi<TRequest, TResponse>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<TResponse>
{
  try {
    const axiosRequest = createAxiosRequest(method, path, formatRequest(request), false);
    const response = await axios(axiosRequest);
    return formatResponse(response.data);
  } catch(error) {
    const err = error as AxiosError;
    const csrfToken = err.response?.headers['X-XSRF-TOKEN'.toLowerCase()];
    if (typeof csrfToken !== 'undefined' && attemptCount < 6)
    {
      localStorage.setItem('XSRF-TOKEN', csrfToken);

      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);

      return callApi(method, path, request, attemptCount + 1);
    }
    throw error;
  }
}

async function callStreamApi<TRequest>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<void>
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const fetchResponse = await fetch(prefix() + path, {
    method: method,
    headers: {
      ...((csrfToken !== null) && { 'X-XSRF-TOKEN': csrfToken }),
      'Content-Type': 'application/json',
    },
    // Fetch requires this to be a string
    body: JSON.stringify(formatRequest(request)),
  });

  if (!fetchResponse.ok) {
    const csrfToken = fetchResponse.headers.get('X-XSRF-TOKEN'.toLowerCase());
    if (typeof csrfToken !== 'undefined' && attemptCount < 6) {
      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);
      return callStreamApi(method, path, request, attemptCount + 1);
    }
  }

  // Download stream
  const readableStream = fetchResponse.body;
  // Regex to get the filename from the Content-Disposition header
  const fileNameRegex = `(?<=filename=").*?(?=")`;
  const fileName = fetchResponse.headers.get('Content-Disposition')?.match(fileNameRegex)![0];
  const decodedFilename = decodeURIComponent(fileName!);
  if (window.WritableStream && readableStream?.pipeTo) {
    // We can assert StreamSaver exists now as this is a client-side context
    const streamSaver = (await streamSaverPromise!).default;
    // Override mitm.html location from jimmywarting.github.io to self-hosted file in php
    streamSaver.mitm = '/stream-saver/mitm.html';
    const fileStream = streamSaver.createWriteStream(decodedFilename);
    return readableStream.pipeTo(fileStream);
  }
}

function createAxiosRequest<TRequest>(callMethod: Method, path: string, request: TRequest, isStreamRequest: boolean): AxiosRequestConfig
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const requestConfig: AxiosRequestConfig =
  {
    method: callMethod,
    url: prefix() + path,
    data: request,
    headers: {
      ...((csrfToken !== null) && {'X-XSRF-TOKEN': csrfToken})
    }
  };
  if (isStreamRequest)
  {
    requestConfig.responseType = 'stream';
    requestConfig.timeout = 99000; // milliseconds
  }
  return requestConfig;
}

//#endregion ApiCalls


//#region Imports

import { EmptyRequest as SubscriptionClientBeastClientCommonControllerControllerEmptyRequest, EmptyResponse as SubscriptionClientBeastClientCommonControllerControllerEmptyResponse } from "@SubscriptionClient/BeastClient/Common/Controller/Controller.gen"
import { Response as SubscriptionClientBeastClientBeastMspBillingDataSyncControllerBillingDataSyncTableListNestedResponse } from "@SubscriptionClient/BeastClient/Beast/Msp/Billing/DataSync/Controller/BillingDataSyncTableListNested.gen"
import { Request as SubscriptionClientBeastClientBeastMspBillingDataSyncControllerBillingDataSyncTableResyncNestedRequest } from "@SubscriptionClient/BeastClient/Beast/Msp/Billing/DataSync/Controller/BillingDataSyncTableResyncNested.gen"
import { EmptyRequest as SubscriptionClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest } from "@SubscriptionClient/ApiServer/Packages/AspNetCore/Controller/Request.gen"
import { Response as SubscriptionClientBeastClientBeastMspBillingExploreProductsControllerExploreProductsGetControllerNestedResponse } from "@SubscriptionClient/BeastClient/Beast/Msp/Billing/ExploreProducts/Controller/ExploreProductsGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesBillingPaymentSummaryControllerPaymentSummarySubscriptionSummaryGetControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Billing/PaymentSummary/Controller/PaymentSummarySubscriptionSummaryGetControllerNested.gen"
import { Response as SubscriptionClientBeastClientBeastMspBillingPaymentSummaryControllerPaymentSummarySubscriptionSummaryGetControllerNestedResponse } from "@SubscriptionClient/BeastClient/Beast/Msp/Billing/PaymentSummary/Controller/PaymentSummarySubscriptionSummaryGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesProductControllerProductNavigationGetControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Product/Controller/ProductNavigationGetControllerNested.gen"
import { ScalePadProductsNavigationDto as SubscriptionClientBeastClientBeastIdentityDtoModelScalePadProductsNavigationDto } from "@SubscriptionClient/BeastClient/Beast/Identity/Dto/Model.gen"
import { Request as SubscriptionClientSubscriptionPackagesUpgradeControllerPurchasePlanControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Upgrade/Controller/PurchasePlanControllerNested.gen"
import { Result as SubscriptionClientPrimitivesResultsPurchasePlanControllerNestedResponse_PublicError_Result } from "@SubscriptionClient/Primitives/Results/PurchasePlanControllerNested/Response_/PublicError_.gen"
import { Request as SubscriptionClientSubscriptionPackagesBillingControllerCustomerCreditCardDetailsGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesBillingControllerCustomerCreditCardDetailsGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Billing/Controller/CustomerCreditCardDetailsGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesBillingControllerSetupCustomerPaymentControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Billing/Controller/SetupCustomerPaymentControllerNested.gen"
import { Result as SubscriptionClientPrimitivesResultsSetupCustomerPaymentControllerNestedResponse_PublicError_Result } from "@SubscriptionClient/Primitives/Results/SetupCustomerPaymentControllerNested/Response_/PublicError_.gen"
import { Request as SubscriptionClientSubscriptionPackagesBillingControllerBillingInfoGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesBillingControllerBillingInfoGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Billing/Controller/BillingInfoGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesBillingQuotesGetQuoteControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Billing/Quotes/GetQuoteControllerNested.gen"
import { Result as SubscriptionClientPrimitivesResultsResponse_PublicError_Result } from "@SubscriptionClient/Primitives/Results/Response_/PublicError_.gen"
import { Request as SubscriptionClientSubscriptionPackagesBillingQuotesAcceptQuoteControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesBillingQuotesAcceptQuoteControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Billing/Quotes/AcceptQuoteControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesBillingControllerSetupIntentCreateControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesBillingControllerSetupIntentCreateControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Billing/Controller/SetupIntentCreateControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesUpgradeControllerAccountBillingAddressUpsertNestedRequest } from "@SubscriptionClient/Subscription/Packages/Upgrade/Controller/AccountBillingAddressUpsertNested.gen"
import { Result as SubscriptionClientPrimitivesResultsEmptyResponse_PublicError_Result } from "@SubscriptionClient/Primitives/Results/EmptyResponse_/PublicError_.gen"
import { Request as SubscriptionClientSubscriptionPackagesConsumptionControllerConsumptionDtoGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesConsumptionControllerConsumptionDtoGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Consumption/Controller/ConsumptionDtoGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesPdContactSubscriptionPdContactInfoGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesPdContactSubscriptionPdContactInfoGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/PdContact/SubscriptionPdContactInfoGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesDetailsControllerSubscriptionDetailsGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesDetailsControllerSubscriptionDetailsGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Details/Controller/SubscriptionDetailsGetControllerNested.gen"
import { EmptyRequest as SubscriptionClientCommonControllerControllerEmptyRequest, EmptyResponse as SubscriptionClientCommonControllerControllerEmptyResponse } from "@SubscriptionClient/Common/Controller/Controller.gen"
import { Response as SubscriptionClientSubscriptionPackagesDetailsControllerSubscriptionDetailsPlanGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Details/Controller/SubscriptionDetailsPlanGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesExpansionControllerExpansionStateGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesExpansionControllerExpansionStateGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Expansion/Controller/ExpansionStateGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesExpansionPackagesOrganizationExpansionOrganizationUnlockControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Expansion/Packages/Organization/ExpansionOrganizationUnlockControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesExpansionPackagesStateExpansionStateGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesExpansionPackagesStateExpansionStateGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Expansion/Packages/State/ExpansionStateGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesGrowControllerSubscriptionGrowControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesGrowControllerSubscriptionGrowControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Grow/Controller/SubscriptionGrowControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesGrowControllerSubscriptionGrowAvailabilitiesControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesGrowControllerSubscriptionGrowAvailabilitiesControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Grow/Controller/SubscriptionGrowAvailabilitiesControllerNested.gen"
import { Response as SubscriptionClientSubscriptionPackagesGrowControllerSubscriptionGrowAvailableActionControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Grow/Controller/SubscriptionGrowAvailableActionControllerNested.gen"
import { Response as SubscriptionClientSubscriptionPackagesPdTeamPdTeamMembersListControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/PdTeam/PdTeamMembersListControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesDetailsControllerSubscriptionGroupPricingDetailsGetControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Details/Controller/SubscriptionGroupPricingDetailsGetControllerNested.gen"
import { Response as SubscriptionClientBeastClientBeastMspControllerSubscriptionDetailsSubscriptionGroupPricingDetailsGetNestedResponse } from "@SubscriptionClient/BeastClient/Beast/Msp/Controller/Subscription/Details/SubscriptionGroupPricingDetailsGetNested.gen"
import { Response as SubscriptionClientSubscriptionPackagesOfferControllerSubscriptionOfferAcceptControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Offer/Controller/SubscriptionOfferAcceptControllerNested.gen"
import { Response as SubscriptionClientSubscriptionPackagesOfferControllerSubscriptionPlanOfferGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Offer/Controller/SubscriptionPlanOfferGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesPricingControllerPricingDtoGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesPricingControllerPricingDtoGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Pricing/Controller/PricingDtoGetControllerNested.gen"
import { Response as SubscriptionClientSubscriptionPackagesProductControllerProductBrandingGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Product/Controller/ProductBrandingGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesProductUnitCountSnapshotControllerProductUnitCountSnapshotConsumeControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesProductUnitCountSnapshotControllerProductUnitCountSnapshotConsumeControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/ProductUnitCountSnapshot/Controller/ProductUnitCountSnapshotConsumeControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesUpgradeControllerPurchasePlanCheckoutSummaryGetControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Upgrade/Controller/PurchasePlanCheckoutSummaryGetControllerNested.gen"
import { Response as SubscriptionClientBeastClientBeastMspBillingSelfUpgradeControllerPurchasePlanCheckoutSummaryGetControllerNestedResponse } from "@SubscriptionClient/BeastClient/Beast/Msp/Billing/SelfUpgrade/Controller/PurchasePlanCheckoutSummaryGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesPricingControllerSelfServePricingDtoGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesPricingControllerSelfServePricingDtoGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Pricing/Controller/SelfServePricingDtoGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesPricingControllerTenantPricingGetControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesPricingControllerTenantPricingGetControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Pricing/Controller/TenantPricingGetControllerNested.gen"
import { Response as SubscriptionClientBeastClientBeastMspControllerSubscriptionTermSubscriptionTermGetNestedResponse } from "@SubscriptionClient/BeastClient/Beast/Msp/Controller/Subscription/Term/SubscriptionTermGetNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesUpgradeControllerSubscriptionUpgradeControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Upgrade/Controller/SubscriptionUpgradeControllerNested.gen"
import { Result as SubscriptionClientPrimitivesResultsSubscriptionUpgradeControllerNestedResponse_SubscriptionUpgradeError_Result } from "@SubscriptionClient/Primitives/Results/SubscriptionUpgradeControllerNested/Response_/SubscriptionUpgradeError_.gen"
import { Request as SubscriptionClientSubscriptionPackagesUpgradeControllerAddonSelectionGetControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Upgrade/Controller/AddonSelectionGetControllerNested.gen"
import { Response as SubscriptionClientBeastClientBeastMspBillingSelfUpgradeControllerAddonSelectionGetControllerNestedResponse } from "@SubscriptionClient/BeastClient/Beast/Msp/Billing/SelfUpgrade/Controller/AddonSelectionGetControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesUpgradeControllerSubscriptionUpgradeAvailabilitiesControllerNestedRequest, Response as SubscriptionClientSubscriptionPackagesUpgradeControllerSubscriptionUpgradeAvailabilitiesControllerNestedResponse } from "@SubscriptionClient/Subscription/Packages/Upgrade/Controller/SubscriptionUpgradeAvailabilitiesControllerNested.gen"
import { Request as SubscriptionClientSubscriptionPackagesUpgradeControllerSelfUpgradeGetControllerNestedRequest } from "@SubscriptionClient/Subscription/Packages/Upgrade/Controller/SelfUpgradeGetControllerNested.gen"
import { Response as SubscriptionClientBeastClientBeastMspBillingSelfUpgradeControllerSelfUpgradeGetControllerNestedResponse } from "@SubscriptionClient/BeastClient/Beast/Msp/Billing/SelfUpgrade/Controller/SelfUpgradeGetControllerNested.gen"

//#endregion Imports


//#region Operations

export function productNavigationGet(request: SubscriptionClientSubscriptionPackagesProductControllerProductNavigationGetControllerNestedRequest): Promise<SubscriptionClientBeastClientBeastIdentityDtoModelScalePadProductsNavigationDto>
{
  return callApi<SubscriptionClientSubscriptionPackagesProductControllerProductNavigationGetControllerNestedRequest, SubscriptionClientBeastClientBeastIdentityDtoModelScalePadProductsNavigationDto>("POST", "/Product/Navigation/Get", request);
}

/**
 * Very lightweight controller to list product branding metadata
 */
export function subscriptionProductBrandingGet(request: SubscriptionClientCommonControllerControllerEmptyRequest): Promise<SubscriptionClientSubscriptionPackagesProductControllerProductBrandingGetControllerNestedResponse>
{
  return callApi<SubscriptionClientCommonControllerControllerEmptyRequest, SubscriptionClientSubscriptionPackagesProductControllerProductBrandingGetControllerNestedResponse>("POST", "/Subscription/Product/Branding/Get", request);
}


//#endregion Operations



import Theme, { removeLegacyTheme } from '~/neo-ui/packages/color/Theme';
import Color from '~/neo-ui/packages/color/Color.gen';
import { useMemo } from 'react';

/**
 * Object that maps a theme to the colors it breaks down into for individual use
 */
export type ThemeMap = {
  context: Color;
  theme: Color;

  foregroundAccent: Color;
  foregroundContrast: Color;
  foregroundLowered: Color;
  foregroundRaised: Color;

  backgroundAccent: Color;
  backgroundContrast: Color;
  backgroundLowered: Color;
  backgroundRaised: Color;
};

export type ThemeMapOption = keyof ThemeMap;

type UseTheme = { themeMap: ThemeMap };
type UseThemes = { mapOfThemeMaps: Map<Theme, ThemeMap> };

/**
 * Provides a breakdown of colors to use for a given theme
 *
 * TODO: As part of color standardization this hook should be adjusted to support any changes required
 *
 * @param theme Theme being used in this context
 */
const useTheme = (theme?: Theme): UseTheme => {
  const themeMap = useMemo(() => themeToThemeMap(theme), [theme]);

  return { themeMap };
};

/**
 * Provides map of theme maps (denoted by its theme, giving a breakdown of colors) to use for a given set of themes
 *
 * TODO: As part of color standardization this hook should be adjusted to support any changes required
 *
 * @param themes Set of Themes being used in this context
 */
export const useThemes = (themes: Set<Theme>): UseThemes => {
  const mapOfThemeMaps = useMemo(() => new Map(Array.from(themes).map(theme => [theme, themeToThemeMap(theme)])), [themes]);

  return { mapOfThemeMaps };
};

const themeToThemeMap = (theme?: Theme): ThemeMap => {
  switch (removeLegacyTheme(theme)) {
    case 'primary':
      return {
        context: 'secondary-050',
        theme: 'light-000',
        foregroundAccent: 'primary-400',
        foregroundContrast: 'light-050',
        foregroundLowered: 'primary-500',
        foregroundRaised: 'primary-300',
        backgroundAccent: 'primary-100',
        backgroundContrast: 'primary-800',
        backgroundLowered: 'primary-200',
        backgroundRaised: 'primary-050',
      };
    case 'secondary':
      return {
        context: 'secondary-050',
        theme: 'light-000',
        foregroundAccent: 'secondary-400',
        foregroundContrast: 'light-050',
        foregroundLowered: 'secondary-500',
        foregroundRaised: 'secondary-300',
        backgroundAccent: 'secondary-050',
        backgroundContrast: 'secondary-400',
        backgroundLowered: 'secondary-100',
        backgroundRaised: 'secondary-200',
      };
    case 'negative':
      return {
        context: 'secondary-050',
        theme: 'light-000',
        foregroundAccent: 'negative-400',
        foregroundContrast: 'light-050',
        foregroundLowered: 'negative-500',
        foregroundRaised: 'negative-300',
        backgroundAccent: 'negative-100',
        backgroundContrast: 'negative-800',
        backgroundLowered: 'negative-200',
        backgroundRaised: 'negative-050',
      };
    case 'warning':
      return {
        context: 'secondary-050',
        theme: 'light-000',
        foregroundAccent: 'warning-400',
        foregroundContrast: 'light-050',
        foregroundLowered: 'warning-500',
        foregroundRaised: 'warning-300',
        backgroundAccent: 'warning-100',
        backgroundContrast: 'warning-800',
        backgroundLowered: 'warning-200',
        backgroundRaised: 'warning-050',
      };
    case 'caution':
      return {
        context: 'secondary-050',
        theme: 'light-000',
        foregroundAccent: 'caution-400',
        foregroundContrast: 'light-050',
        foregroundLowered: 'caution-500',
        foregroundRaised: 'caution-300',
        backgroundAccent: 'caution-100',
        backgroundContrast: 'caution-800',
        backgroundLowered: 'caution-200',
        backgroundRaised: 'caution-050',
      };
    case 'positive':
      return {
        context: 'secondary-050',
        theme: 'light-000',
        foregroundAccent: 'positive-400',
        foregroundContrast: 'light-050',
        foregroundLowered: 'positive-500',
        foregroundRaised: 'positive-300',
        backgroundAccent: 'positive-100',
        backgroundContrast: 'positive-800',
        backgroundLowered: 'positive-200',
        backgroundRaised: 'positive-050',
      };
    case 'info':
      return {
        context: 'secondary-050',
        theme: 'light-000',
        foregroundAccent: 'info-400',
        foregroundContrast: 'light-050',
        foregroundLowered: 'info-500',
        foregroundRaised: 'info-300',
        backgroundAccent: 'info-100',
        backgroundContrast: 'info-800',
        backgroundLowered: 'info-200',
        backgroundRaised: 'info-050',
      };
    case 'excellent':
      return {
        context: 'secondary-050',
        theme: 'light-000',
        foregroundAccent: 'excellent-400',
        foregroundContrast: 'light-050',
        foregroundLowered: 'excellent-500',
        foregroundRaised: 'excellent-300',
        backgroundAccent: 'excellent-100',
        backgroundContrast: 'excellent-800',
        backgroundLowered: 'excellent-200',
        backgroundRaised: 'excellent-050',
      };
    // light
    default:
      return {
        context: 'secondary-050',
        theme: 'light-000',
        foregroundAccent: 'light-100',
        foregroundContrast: 'dark-700',
        foregroundLowered: 'light-400',
        foregroundRaised: 'light-050',
        backgroundAccent: 'secondary-050',
        backgroundContrast: 'dark-700',
        backgroundLowered: 'secondary-100',
        backgroundRaised: 'secondary-050',
      };
  }
};

export default useTheme;

type BorderRadius = 'radius0' | 'radius100' | 'radius200' | 'radius300' | 'radius400' | 'radius425' | 'radius500';

/**
 * Some common border radius values for styling components with rounded corners
 */
export const borderRadiusMap: { [index: string]: string } = {
  radius0: '0rem', // 0px
  radius100: '0.125rem', // 2px
  radius200: '0.375rem', // 6px
  radius300: '0.5rem', // 8px
  radius400: '0.625rem', // 10px
  radius425: '0.75rem', // 12px
  radius500: '1rem', // 16px
};

/**
 * Converts the given padding value to its css value in rem
 */
export const borderRadiusToCode = (borderRadius?: BorderRadius) => {
  if (borderRadius === undefined) {
    return borderRadiusMap.radius0;
  }
  return borderRadiusMap[borderRadius];
};

export default BorderRadius;

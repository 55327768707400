import useGlobalStyleInjection from '~/scalepad/packages/scalepad-help-center-icon/packages/userflow/useGlobalStyleInjection';
import { useCallback } from 'react';

export const resourceCenterClassName = 'userflowjs-resource-center';

const styleOverride = document.createElement('style');
const styleOverrideId = 'userflow-resource-center-overload';
const styleOverrideHiddenInnerHtml = `
.${resourceCenterClassName} {
  visibility: hidden;
}`;

export const injectUserflowStyleOverride = () => {
  styleOverride.id = styleOverrideId;
  styleOverride.innerHTML = styleOverrideHiddenInnerHtml;

  document.head.appendChild(styleOverride);
};

/**
 * Because of Userflow's lack of API, I've consolidated the majority of our
 * hacky manipulations into this hook, aside for injecting the actual HTML
 * element we depend on (see `injectUserflowStyleOverride`), as that needed
 * to be injected quite early in the lifecycle.
 */
const useUserflowOverrides = () => {
  // Move the positioning of the userflow resource center to match our app
  useGlobalStyleInjection(`
    .${resourceCenterClassName} {
        --userflow-resource-center-padding-x: 4px;
        --userflow-resource-center-padding-y: 44px;
        
        top: var(--userflow-resource-center-padding-y) !important;
        right: var(--userflow-resource-center-padding-x) !important;
        bottom: unset !important;
        left: unset !important;
    }
`);

  /**
   * This mutates the injected styles to set the resource center visibility.
   * This is used in order to control the initial state in our own way,
   * and hide the help center icon if a userflow resource center does not exist.
   */
  const setGloballyVisible = useCallback((visible: boolean) => {
    styleOverride.innerHTML = visible ? '' : styleOverrideHiddenInnerHtml;
  }, []);

  return { setGloballyVisible };
};

export default useUserflowOverrides;

import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import useProductNavigate from '~/wm/packages/sp-account/packages/app-switcher/hooks/useProductNavigate';
import React from 'react';
import ProductIcon from '~/wm/packages/sp-account/packages/app-switcher/ProductIcon';
import { ProductDto } from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/ScalePadProductsContext';

export type ProductLinkIframeProps = {
  product: ProductDto;
  onProductSelected: () => void;
  isExternal: boolean;
};

const ProductLinkIframe = ({ product, onProductSelected, isExternal }: ProductLinkIframeProps) => {
  const { navigateToProduct } = useProductNavigate();

  const styles = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    user-select: none;
    padding-block: 16px;
    padding-inline: 24px;
  `;

  return isExternal ? (
    <a
      href={product.url}
      css={styles}
      target="_blank"
    >
      <ProductIcon product={product} />
      <Icon
        icon={'GoExternal'}
        color={'primary-400'}
      />
    </a>
  ) : (
    <div
      css={styles}
      onClick={() => {
        navigateToProduct(product);
        onProductSelected();
      }}
    >
      <ProductIcon product={product} />
    </div>
  );
};

export default ProductLinkIframe;

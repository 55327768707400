import { useEffect } from 'react';

const useClickAwayListener = (callback: () => void, ...elements: (HTMLElement | null)[]) => {
  useEffect(() => {
    const clickHandler = async (e: MouseEvent) => {
      const isInsideClicked = elements
        .filter(element => !!element)
        .some(element => element!.contains(e.target as Node) || element === e.target);

      if (isInsideClicked) {
        return;
      }

      callback();
    };

    const blurHandler = () => {
      // Dismiss when window loses focus.
      // Most importantly, this also handles the iframe case.
      callback();
    };

    document.addEventListener('click', clickHandler, true);
    window.addEventListener('blur', blurHandler);

    return () => {
      document.removeEventListener('click', clickHandler, true);
      window.removeEventListener('blur', blurHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...elements, callback]);
};

export default useClickAwayListener;

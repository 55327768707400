import { ProductDto } from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/ScalePadProductsContext';
import AppSwitcherImage from '~/wm/packages/sp-account/packages/app-switcher/AppSwitcherImage';

export type ProductIconProps = {
  product: ProductDto;
};

const ProductIcon = ({ product }: ProductIconProps) => (
  <AppSwitcherImage
    src={product.icon}
    css={{ height: '24px' }}
  />
);

export default ProductIcon;

import { useEffect, useState } from 'react';
import { resourceCenterClassName } from '~/scalepad/packages/scalepad-help-center-icon/packages/userflow/useUserflowOverrides';

/**
 * This hook provides a way to check if a Userflow resource center exists
 * for the current page.
 */
const useUserflowResourceCenterAvailability = () => {
  const [isResourceCenterAvailable, setResourceCenterAvailable] = useState(
    () => document.getElementsByClassName(resourceCenterClassName).length > 0,
  );

  // Note: This adds a debounced DOM check to each DOM mutation, which should be negligible,
  // but if it becomes an issue we can adjust our approach for getting this state.
  // For example, we could add a timeout and use it only for the initial state,
  // and react to page changes, or find other ways to determine if the resource center
  // availability changes.
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    const checkExistence = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setResourceCenterAvailable(document.getElementsByClassName(resourceCenterClassName).length > 0);
      }, 100); // 100ms timeout for debounce
    };

    // Watch if the resource center element is added/removed from the DOM
    const observer = new MutationObserver(() => {
      checkExistence();
    });

    // Start observing everything
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return { isResourceCenterAvailable };
};

export default useUserflowResourceCenterAvailability;

type LockState = {
  /**
   * Entities are categorized into buckets
   * Map<bucket, entity ids>
   */
  lockedEntities: Map<string, Set<string>>;
};

export const lockInitialState: LockState = { lockedEntities: new Map() };

export default LockState;

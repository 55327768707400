import { NoInfer } from '~/extensions/packages/types/noInfer';
import { EventMetadataMap, EventToTrack } from '~/extensions/packages/tracking/events/TrackingEvents';
import { useCallback } from 'react';

export enum TrackingServices {
  EventProcessor = 'EVENT PROCESSOR',
}

export type UseEventTracking = (service: TrackingServices) => {
  trackEvent: <TEvent extends EventToTrack>(event: TEvent, metaData: EventMetadataMap[NoInfer<TEvent>]) => void;
};

export const useEventTracking: UseEventTracking = (service: TrackingServices) => {
  const trackEvent = useCallback(
    <TEvent extends EventToTrack = never>(event: TEvent, metaData: EventMetadataMap[NoInfer<TEvent>]) => {
      switch (service) {
        case TrackingServices.EventProcessor:
          if (typeof analytics === 'undefined' || typeof analytics.track === 'undefined') {
            return;
          }
          analytics.track(event, metaData);
          break;
      }
    },
    [service],
  );

  return { trackEvent };
};

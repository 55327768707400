import { applyMiddleware, compose, createStore, Reducer } from 'redux';
import createSagaMiddlware, { Saga } from 'redux-saga';

const sagaMiddleware = createSagaMiddlware();

const composeEnhancers =
  (typeof window !== 'undefined' &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-underscore-dangle, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const getAppliedMiddleware = () => applyMiddleware(sagaMiddleware);

export const configureStore = <TRootState extends object>(rootReducer: Reducer, rootSaga?: Saga, preloadedState?: TRootState) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
    composeEnhancers(getAppliedMiddleware()),
  );

  if (rootSaga) {
    sagaMiddleware.run(rootSaga);
  }

  return store;
};

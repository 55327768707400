import React, { createContext, useEffect, useState } from 'react';
import { ScalePadIdentity } from '~/scalepad/packages/scalepad-product-script/packages/injection/functions/identify';

export type ScalePadUidContextType = {
  identity: ScalePadIdentity | undefined;
};

const ScalePadUidContext = createContext<ScalePadUidContextType>({
  identity: undefined,
});

export const ScalePadUidProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [identity, setIdentity] = useState<ScalePadIdentity | undefined>(window.scalepad.state.identity);

  useEffect(() => {
    const handleIdentityChanged = () => {
      setIdentity(window.scalepad.state.identity);
    };

    document.addEventListener('scalePadIdentityChanged', handleIdentityChanged);
    return () => document.removeEventListener('scalePadIdentityChanged', handleIdentityChanged);
  }, []);

  return <ScalePadUidContext.Provider value={{ identity }}>{children}</ScalePadUidContext.Provider>;
};

export default ScalePadUidContext;

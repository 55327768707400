import { Entity } from '~/legacy-ui/packages/lock/model/Entity';
import { LockActionBuilder } from '../LockAction';

export type EntityUnlockKey = 'Entity/Unlock';
export const entityUnlockKey: EntityUnlockKey = 'Entity/Unlock';

export type EntityUnlock = LockActionBuilder<'Entity/Unlock', EntityUnlockPayload>;

export type EntityUnlockPayload = { entities: Entity[] };

const entityUnlockAction = (payload: EntityUnlockPayload): EntityUnlock => ({
  type: 'Entity/Unlock',
  payload,
});

export default entityUnlockAction;

import { css, keyframes } from '@emotion/react';

const animatedBackground = keyframes`
  from {
    background-position: 3000px 0;
  }
  to {
    background-position: -50px 0;
  }
`;

const progressAnimation = css`
  animation: ${animatedBackground} 2s cubic-bezier(0.9, 0, 0, 0.9) infinite reverse;
`;

export default progressAnimation;

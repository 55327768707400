import GlobalError from '~/neo-ui/packages/error/model/GlobalError';

export type ApiErrorType = 'session-expired' | 'access-denied' | 'communication-error' | 'validation-error';

export type GetApiErrorDefinition = GlobalError;

const getApiErrorDefinition = (apiErrorType: ApiErrorType): GetApiErrorDefinition => {
  switch (apiErrorType) {
    case 'session-expired':
      return {
        title: 'Your session has expired',
        message: 'You are being signed out. Please sign back in and contact support if the problem persists.',
      };
    case 'access-denied':
      return {
        title: 'You do not have permission to perform this action',
        message: 'Please contact your account administrator.',
      };
    case 'validation-error':
      return {
        title: 'Something went wrong in validating your form',
        message: 'Please ensure your input is both valid and accurate. If this error persists please contact support.',
      };
    case 'communication-error':
      return {
        title: 'Something went wrong',
        message: 'Try refreshing the page or contact support if the problem persists.',
      };
  }
};

export default getApiErrorDefinition;

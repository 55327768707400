import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import ProductLinkIframe from '~/wm/packages/sp-account/packages/app-switcher/ProductLinkIframe';
import ProductIcon from '~/wm/packages/sp-account/packages/app-switcher/ProductIcon';
import { ProductDto } from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/ScalePadProductsContext';
import ProductRegionSection from '~/wm/packages/sp-account/packages/app-switcher/ProductRegion/ProductRegionSection';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type ProductLinkProps = {
  product: ProductDto;
  isOwned: boolean;
  onProductSelected: () => void;
  isIframePrototype?: boolean;
};

const ProductLink = ({ product, isOwned, onProductSelected, isIframePrototype }: ProductLinkProps) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const styles = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    user-select: none;
    padding-block: 12px;
    padding-inline: 24px;
  `;

  const isExternal = isOwned ? product.isExternalApp : false;

  const disableProductLink = isOwned && product.productRegionNavigationDtos.length > 0;

  return !isIframePrototype ? (
    disableProductLink ? (
      <div
        css={styles}
        {...(isExternal && { target: '_blank' })}
      >
        <ProductIcon product={product} />
        <ProductRegionSection
          product={product.label}
          regions={product.productRegionNavigationDtos}
        />
        {isExternal && (
          <Icon
            icon={'GoExternal'}
            color={'primary-400'}
          />
        )}
      </div>
    ) : (
      <a
        href={product.url}
        onClick={() =>
          trackEventProcessor(isOwned ? 'app_bar_owned_product_visited' : 'app_bar_not_owned_product_visited', {
            targetProduct: product.label,
          })
        }
        css={styles}
        {...(isExternal && { target: '_blank' })}
      >
        <ProductIcon product={product} />
        {isExternal && (
          <Icon
            icon={'GoExternal'}
            color={'primary-400'}
          />
        )}
      </a>
    )
  ) : (
    <ProductLinkIframe
      product={product}
      onProductSelected={onProductSelected}
      isExternal={isExternal}
    />
  );
};

export default ProductLink;

import injectApi from '~/scalepad/packages/scalepad-product-script/packages/injection/injectApi';
import scriptConfig from '~/scalepad/packages/scalepad-product-script/scriptConfig';
import { injectUserflowStyleOverride } from '~/scalepad/packages/scalepad-help-center-icon/packages/userflow/useUserflowOverrides';

// This JavaScript file is run within each product.
// Here we inject the ScalePad API, and render the top bar.

// Inject ScalePad snippet API
injectApi();

// Inject userflow style overrides, allows customization of the userflow resource center.
// Need to inject nice & early, as it constrains the risk of userflow showing up
// before we've added our styling adjustments.
injectUserflowStyleOverride();

if (scriptConfig.renderOnPageLoad) {
  window.scalepad.showAppBar();
} else {
  window.scalepad.hideAppBar();
}

import { takeLatest } from 'redux-saga/effects';

import { PageReloadActionType, PageReloadRequestedAction } from './pageReloadActions';

export function* pageReloadWorkerSaga(_action: PageReloadRequestedAction) {
  window.location.reload();
}

export function* pageReloadWatcherSaga() {
  yield takeLatest(PageReloadActionType.ReloadRequested, pageReloadWorkerSaga);
}

import { FC } from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type ScalePadTopIconProps = { icon: IconType; onClick: () => void; isActive?: boolean };

const ScalePadTopIcon: FC<ScalePadTopIconProps> = ({ icon, onClick, isActive = false }) => {
  return (
    <div
      onClick={onClick}
      css={css`
        display: flex;
        align-items: center;
        padding: 0.4375rem;
        border-radius: 0.5rem;
        cursor: pointer;

        &:hover {
          background: ${colorToCode('light-000-16')};
        }

        ${isActive ? `background: ${colorToCode('light-000-24')};` : ''}
      `}
    >
      <Icon
        icon={icon}
        color={'light-000'}
      />
    </div>
  );
};

export default ScalePadTopIcon;

import { ProgressActionBuilder } from '../progressActions';

export type ProgressStoppedKey = 'Progress/Stopped';
export const progressStoppedKey: ProgressStoppedKey = 'Progress/Stopped';

export type ProgressStoppedPayload = { progressKey: string };
export type ProgressStopped = ProgressActionBuilder<'Progress/Stopped', ProgressStoppedPayload>;

const progressStoppedAction = (payload: ProgressStoppedPayload): ProgressStopped => ({
  type: 'Progress/Stopped',
  payload,
});

export default progressStoppedAction;

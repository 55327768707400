import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import AppSwitcher from '~/wm/packages/sp-account/packages/app-switcher/AppSwitcher';
import { css } from '@emotion/react';
import useScalePadOrigin from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/useScalePadOrigin';
import { FC } from 'react';
import AppSwitcherImage from '~/wm/packages/sp-account/packages/app-switcher/AppSwitcherImage';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import ScalePadHelpCenterIcon from '~/scalepad/packages/scalepad-help-center-icon/ScalePadHelpCenterIcon';

export const topBarHeightPx = 40;

const ScalePadTopNav: FC<{ isIframePrototype?: boolean }> = ({ isIframePrototype }) => {
  const { forceAbsolutePath } = useScalePadOrigin();
  const manageAccountUrl = forceAbsolutePath('/account/home');
  const scalePadCommunityUrl = COMMUNITY_URL ?? '';
  let appSwitcherImage = '/i/sp-account.svg';

  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const currentMonth = new Date().getMonth() + 1;

  if (currentMonth === 12 || currentMonth === 1) {
    appSwitcherImage = '/i/sp-account-winter.png';
  }

  return (
    <nav
      className="top-app-bar"
      css={css`
        position: sticky;
        top: 0;
        left: 0;
        height: var(--app-bar-height);
        padding: 4px;
        z-index: 1040;
        background-color: ${colorToCode('dark-900')}; // #0a1c2b;
        color: ${colorToCode('light-000')};
        display: flex;
        align-items: center;
        box-shadow: 0 1px 0 0 ${colorToCode('light-000-24')};
      `}
    >
      <button
        id="app-switcher-btn"
        type="button"
        css={css`
          all: unset;
          outline: none;
          height: 100%;
          border-radius: 5px;
          padding: 0 12px;

          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          &:focus,
          &:focus-visible {
            outline: none;
            background-color: ${colorToCode('light-000-16')};
          }

          &:hover {
            cursor: pointer;
            &:not(:focus) {
              background-color: ${colorToCode('light-000-08')};
            }
          }

          &::after {
            display: inline;
            content: '';
            height: 32px;
            width: 1px;
            background-color: ${colorToCode('light-000-24')};
            position: absolute;
            top: calc(50% - 1rem);
            right: -4px;
          }
        `}
      >
        <AppSwitcherImage
          css={css`
            width: 16px;
            height: 16px;
          `}
          src="/i/app-switcher-icon.svg"
        />
      </button>

      <AppSwitcher
        manageAccountUrl={manageAccountUrl}
        scalePadCommunityUrl={scalePadCommunityUrl}
        isIframePrototype={isIframePrototype}
      />
      <div
        css={css`
          flex: 1;
        `}
      >
        <a
          href={manageAccountUrl}
          target={'_blank'}
          onClick={() => trackEventProcessor('app_bar_scalepad_account_visited', {})}
        >
          <AppSwitcherImage
            src={appSwitcherImage}
            css={css`
              padding: 0 16px;
              height: 16px;
              user-select: none;
            `}
          />
        </a>
      </div>

      <ScalePadHelpCenterIcon />
    </nav>
  );
};

export default ScalePadTopNav;

import { Entity } from '~/legacy-ui/packages/lock/model/Entity';
import { LockActionBuilder } from '../LockAction';

export type EntityLockKey = 'Entity/Lock';
export const entityLockKey: EntityLockKey = 'Entity/Lock';

export type EntityLockPayload = { entities: Entity[] };

export type EntityLock = LockActionBuilder<'Entity/Lock', EntityLockPayload>;

const entityLockAction = (payload: EntityLockPayload): EntityLock => ({
  type: 'Entity/Lock',
  payload,
});

export default entityLockAction;

import { Action } from '~/extensions/packages/state/abstractActions';

export enum PageReloadActionType {
  ReloadRequested = 'ReloadRequested',
}

export interface PageReloadRequestedAction extends Action<PageReloadActionType.ReloadRequested> {}

export const pageReloadRequestedAction = (): PageReloadRequestedAction => ({
  type: PageReloadActionType.ReloadRequested,
  payload: {},
});

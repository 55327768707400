import { ProductRegionNavigationDto } from '@SubscriptionClient/BeastClient/Beast/Identity/Dto/Model.gen';
import { css } from '@emotion/react';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type ProductRegionSectionProps = {
  product: string;
  regions: ProductRegionNavigationDto[];
};

const ProductRegionSection = ({ product, regions }: ProductRegionSectionProps) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      {regions.map((region, index) => (
        <a
          key={index}
          href={region.url}
          onClick={() =>
            trackEventProcessor('app_bar_owned_product_visited', {
              targetProduct: product,
            })
          }
          css={css`
            display: flex;
            justify-content: space-between;
            padding: 0 16px;

            /* Link styling */
            color: black;
            text-decoration: none;
            &:hover {
              color: black;
              text-decoration: none;
            }
          `}
        >
          <img
            src={region.icon}
            css={{ height: '24px', paddingRight: '4px' }}
          />
          <span>{region.label}</span>
        </a>
      ))}
    </div>
  );
};
export default ProductRegionSection;

import { css } from '@emotion/react';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { hideAlertAction, showAlertAction } from '~/legacy-ui/packages/alert/state/action/alertActions';
import ProgressBar from '~/neo-ui/packages/progress/packages/progress-bar/ProgressBar';
import { ProgressReceived, progressReceivedKey } from '~/neo-ui/packages/progress/state/action/received/progressReceivedAction';
import { progressStoppedKey } from '~/neo-ui/packages/progress/state/action/stopped/progressStoppedAction';
import ProgressState from '~/neo-ui/packages/progress/state/model/ProgressState';
import selectProgressState from '~/neo-ui/packages/progress/state/selector/selectProgressState';

function* alertProgressWatcher() {
  yield all([
    takeLatest(progressReceivedKey, function* (action: ProgressReceived) {
      const progressState: ProgressState = yield select(selectProgressState);

      // arbitrarily get one progress
      const progress = progressState[Object.keys(progressState).sort()[0]];
      yield put(
        showAlertAction({
          theme: 'secondary',
          heading: action.payload.progressLabel,
          children: (
            <div
              css={css`
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 0.3125rem;
              `}
            >
              <span>{progress.message}</span>
              <ProgressBar
                progressPercentage={progress.percentage}
                theme="dark"
              />
            </div>
          ),
        }),
      );
    }),
    takeLatest(progressStoppedKey, function* () {
      const progressState: ProgressState = yield select(selectProgressState);
      if (Object.values(progressState).length === 0) {
        // hide alert if progress is all finished
        yield put(hideAlertAction());
      }
    }),
  ]);
}

export default alertProgressWatcher;

import { produce } from 'immer';
import { assertNever } from '~/extensions/packages/types/assertNever';
import ModalAction, { ModalActionType } from '../action/ModalAction';
import ModalState, { modalInitialState } from '../model/ModalState';

const modalReducer = (state: ModalState = modalInitialState, action: ModalAction) =>
  produce(state, (draft: ModalState) => {
    switch (action.type) {
      case ModalActionType.ShowModal:
        draft.modalProps = { ...action.payload, isOpen: true };
        break;
      case ModalActionType.HideModal:
        draft.modalProps.isOpen = false;
        break;
      default:
        assertNever(action);
        break;
    }
  });

export default modalReducer;

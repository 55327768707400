type Padding = 'padding0' | 'padding100' | 'padding200' | 'padding300' | 'padding350' | 'padding400' | 'padding500';

/**
 * Some common padding values for styling components
 */
export const paddingMap: { [padding in Padding]: string } = {
  padding0: '0rem',
  padding100: '0.625rem', // 10px
  padding200: '0.9375rem', // 15px
  padding300: '1.25rem', // 20px
  padding350: '1.50rem',
  padding400: '1.875rem', // 30px
  padding500: '3.125rem', // 50px
};

/**
 * Converts the given padding value to its css value in rem
 */
export const paddingToCode = (padding: Padding) => {
  return paddingMap[padding];
};

export default Padding;

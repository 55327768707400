import React, { createContext, useState } from 'react';

export type PageLoadingContextType = {
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const PageLoadingContext = createContext<PageLoadingContextType | undefined>(undefined);

export const PageLoadingProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isLoading, setLoading] = useState(false);

  return <PageLoadingContext.Provider value={{ isLoading, setLoading }}>{children}</PageLoadingContext.Provider>;
};

export default PageLoadingContext;

import { AxiosError } from 'axios';
import { ApiServerErrorResponseDto } from '~/wm/packages/api/packages/api-error/model/ApiServerErrorResponseDto';
import { ApiErrorType } from '~/wm/packages/api/packages/api-error/model/getApiErrorDefinition';

type ErrorCase =
  | {
      type: Exclude<ApiErrorType, 'validation-error'>;
    }
  | {
      type: 'validation-error';
      payload: ApiServerErrorResponseDto;
    };

export type ApiResult<TResponse> = { type: 'success'; data: TResponse } | { type: 'error'; data: ErrorCase };

/**
 * Temporary wrapper around API requests that will become a part of the generated client.
 *
 * This converts the currently-provided axios response into a API result.
 */
const callApiOrError = async <TResponse>(apiCall: () => Promise<TResponse>): Promise<ApiResult<TResponse>> => {
  try {
    return { type: 'success', data: await apiCall() };
  } catch (error) {
    const err = error as AxiosError;
    const errorResponse = ((): ErrorCase => {
      switch (err.response?.status) {
        case 400:
          return {
            type: 'validation-error',
            payload: err.response.data as ApiServerErrorResponseDto,
          };
        case 401:
          return { type: 'session-expired' };
        case 403:
          return { type: 'access-denied' };
        default:
          return { type: 'communication-error' };
      }
    })();
    return { type: 'error', data: errorResponse };
  }
};

export default callApiOrError;

import { hideAppBar, showAppBar } from '~/scalepad/packages/scalepad-product-script/packages/injection/functions/showAppBar';
import identify, { ScalePadIdentity } from '~/scalepad/packages/scalepad-product-script/packages/injection/functions/identify';

declare global {
  interface Window {
    scalepad: {
      identify: (identity?: ScalePadIdentity | null) => void;
      state: { identity: ScalePadIdentity | undefined };
      showAppBar: () => void;
      hideAppBar: () => void;
    };
  }
}

const injectApi = () => {
  window.scalepad = window.scalepad || {};
  window.scalepad.state = window.scalepad.state || { identity: undefined };

  window.scalepad.showAppBar = showAppBar;
  window.scalepad.hideAppBar = hideAppBar;
  window.scalepad.identify = identify;
};

export default injectApi;

import { combineReducers } from 'redux';
import AlertState from '../../legacy-ui/packages/alert/state/model/AlertState';
import alertReducer from '../../legacy-ui/packages/alert/state/reducer/alertReducer';
import LockState from '../../legacy-ui/packages/lock/state/model/LockState';
import lockReducer from '../../legacy-ui/packages/lock/state/reducer/lockReducer';
import ModalState from '../../legacy-ui/packages/modal/packages/alert-modal/state/model/ModalState';
import modalReducer from '../../legacy-ui/packages/modal/packages/alert-modal/state/reducer/modalReducer';
import ProgressState from '../packages/progress/state/model/ProgressState';
import progressReducer from '../packages/progress/state/reducer/progressReducer';

export type UiState = {
  alert: AlertState;
  modal: ModalState;
  progress: ProgressState;
  lock: LockState;
};

const uiReducer = combineReducers<UiState>({
  alert: alertReducer,
  modal: modalReducer,
  progress: progressReducer,
  lock: lockReducer,
});

export default uiReducer;

import { useCallback, useEffect, useMemo, useState } from 'react';
import useUserflowOverrides from '~/scalepad/packages/scalepad-help-center-icon/packages/userflow/useUserflowOverrides';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import useUserflowResourceCenterAvailability from '~/scalepad/packages/scalepad-help-center-icon/packages/userflow/useUserflowResourceCenterAvailability';
import { useUserflowContext } from '~/scalepad/packages/scalepad-help-center-icon/packages/userflow/UserflowProvider';

/**
 * This hook provides a way to control the global resource center.
 * @returns An object with the following properties:
 * - `isAvailable`: Whether or not the resource center is available on the
 *    current page. This can refer to either N/A or not yet loaded.
 * - `isActive`: Whether or not the resource center is currently active / open.
 * - `toggle`: A function to toggle the resource center.
 * - `setActive`: A function to set the active state of the resource center.
 */
const useResourceCenter = () => {
  const { setGloballyVisible } = useUserflowOverrides();
  const userflow = useUserflowContext();
  const { isResourceCenterAvailable } = useUserflowResourceCenterAvailability();
  const isAvailable =
    // Userflow is loaded, and a resource center exists for the page
    userflow && isResourceCenterAvailable;

  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);

  const setActive = useCallback(
    (active: boolean) => {
      if (!userflow) {
        // Not loaded yet.
        return;
      }

      if (active) {
        userflow.openResourceCenter();
        trackEvent('app_bar_help_center_opened', {});
      } else {
        userflow.closeResourceCenter();
        trackEvent('app_bar_help_center_closed', {});
      }
      setActiveInternal(active);
      // We align the globally visible state with the internal state
      // This is because, userflow shows its own ugly question mark
      // if you don't hide it, and we want to control the visibility.
      // Now, you may know the userflow API and tell me, why don't you
      // use `setResourceCenterLauncherHidden`? Well, if we used that,
      // we'd not be able to use the existence of the DOM element,
      // to determine whether or not a resource center should exist.
      // And it's important that we can do that, because we want to
      // hide the help center icon if there is no available resource
      // center for the page in question.
      setGloballyVisible(active);
    },
    [setGloballyVisible, trackEvent, userflow],
  );

  const [isActive, setActiveInternal] = useState(false);

  useEffect(() => {
    if (!userflow) {
      return;
    }

    const onStateChanged = () => {
      // Covers if e.g., resource center was closed due to clicking a link
      setActive(userflow.getResourceCenterState()?.isOpen ?? false);
    };

    userflow.on('resourceCenterChanged', onStateChanged);
    return () => userflow.off('resourceCenterChanged', onStateChanged);
  }, [setActive, userflow]);

  return useMemo(
    () => ({
      isAvailable,
      isActive,
      toggle: () => setActive(!isActive),
      setActive,
    }),
    [isAvailable, isActive, setActive],
  );
};

export default useResourceCenter;

import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import ProductLink from '~/wm/packages/sp-account/packages/app-switcher/ProductLink';

import { ProductDto } from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/ScalePadProductsContext';

export type ProductLinksProps = {
  products: ProductDto[];
  isOwned: boolean;
  onProductSelected: (product: ProductDto) => void;
  isIframePrototype?: boolean;
};

const ProductLinks = ({ products, isOwned, onProductSelected, isIframePrototype }: ProductLinksProps) => {
  const theme = useTheme();

  return (
    <>
      {products.map(product => (
        <li
          key={product.url}
          className="product-link"
          css={{
            ['&:hover']: {
              backgroundColor: colorToCode(theme.themeMap.backgroundAccent),
              cursor: product.productRegionNavigationDtos.length > 0 ? 'default' : 'pointer',
            },
          }}
        >
          <ProductLink
            product={product}
            isOwned={isOwned}
            onProductSelected={() => {
              onProductSelected(product);
            }}
            isIframePrototype={isIframePrototype}
          />
        </li>
      ))}
    </>
  );
};

export default ProductLinks;

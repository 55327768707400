export type ScalePadIdentity = {
  scalepadAccountId: string;
  scalepadUserId: string;
};

const identify = (identity?: ScalePadIdentity | null) => {
  // Update identity state
  window.scalepad.state.identity = identity || undefined;

  // Dispatch identity changed event
  const event = new CustomEvent('scalePadIdentityChanged');
  document.dispatchEvent(event);
};

export default identify;

type ScalePadConfig = {
  /**
   * If true, the top bar will be rendered automatically.
   * This can avoid layout shift, but may not be necessary for
   * SPAs.
   */
  renderOnPageLoad: boolean;
};

const scriptQueryConfig = ((): URLSearchParams => {
  // Origin of wherever this script was loaded from
  const currentScript = document.currentScript as HTMLScriptElement | null;
  if (currentScript) {
    try {
      return new URL(currentScript.src).searchParams;
    } catch (err) {}
  }

  return new URLSearchParams();
})();

const scriptConfig: ScalePadConfig = {
  renderOnPageLoad: scriptQueryConfig.get('renderOnPageLoad') === 'true',
};

export default scriptConfig;

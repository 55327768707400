import { produce } from 'immer';
import { assertNever } from '~/extensions/packages/types/assertNever';
import LockAction from '../action/LockAction';
import LockState, { lockInitialState } from '../model/LockState';

const lockReducer = (state = lockInitialState, action: LockAction) =>
  produce(state, (draft: LockState) => {
    switch (action.type) {
      case 'Entity/Lock':
        for (const { bucket, entityId } of action.payload.entities) {
          const lockedEntitiesBucket = new Set(draft.lockedEntities.get(bucket) ?? []);
          lockedEntitiesBucket.add(entityId);
          draft.lockedEntities.set(bucket, lockedEntitiesBucket);
        }
        break;
      case 'Entity/Unlock':
        for (const { bucket, entityId } of action.payload.entities) {
          draft.lockedEntities.get(bucket)?.delete(entityId);
        }
        break;
      default:
        assertNever(action);
        break;
    }
  });

export default lockReducer;

import { call, delay } from 'redux-saga/effects';

const standardFrequencyMs = () => Math.random() * (1234 - 777) + 777;

/**
 * Calls the provided action continually per provided frequency
 */
function* pollingWorker(action: () => Generator, frequencyMs?: number) {
  while (true) {
    yield call(action);
    yield delay(frequencyMs || standardFrequencyMs());
  }
}

export default pollingWorker;

import { FC } from 'react';
import ScalePadTopIcon from '~/scalepad/ScalePadTopIcon';
import useResourceCenter from '~/scalepad/packages/scalepad-help-center-icon/hooks/useResourceCenter';

const ScalePadHelpCenterIcon: FC = () => {
  const { isAvailable, isActive, toggle } = useResourceCenter();

  if (!isAvailable) {
    // Resource Center not applicable or not yet loaded for this page
    return null;
  }

  return (
    <ScalePadTopIcon
      icon={'Support'}
      isActive={isActive}
      onClick={toggle}
    />
  );
};

export default ScalePadHelpCenterIcon;

import { captureException as Sentry_captureException } from '@sentry/react';
import SeverityLevel from '~/extensions/packages/sentry/types/SeverityLevel';

/**
 * Capture an exception that should be sent to Sentry
 * @param exception
 * @param severityLevel
 */
const captureException = <T>(exception: T, severityLevel: SeverityLevel) => Sentry_captureException(exception, { level: severityLevel });

export default captureException;

import { useContext } from 'react';
import ScalePadOriginContext from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/ScalePadOriginContext';

const useScalePadOrigin = (): {
  scalePadOrigin: string;
  forceAbsolutePath: (relativeOrAbsolutePath: string) => string;
} => {
  const { scalePadOrigin } = useContext(ScalePadOriginContext);
  return {
    scalePadOrigin,
    /**
     * If the path is relative, it will be prefixed with the origin.
     * This is helpful for components that need to be rendered outside of
     * the ScalePad origin (e.g., cross-product components).
     */
    forceAbsolutePath: (relativeOrAbsolutePath: string) =>
      relativeOrAbsolutePath.startsWith('/') ? `${scalePadOrigin}${relativeOrAbsolutePath}` : relativeOrAbsolutePath,
  };
};

export default useScalePadOrigin;

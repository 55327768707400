import { AlertModalProps } from '../../AlertModal';

type ModalState = {
  modalProps: AlertModalProps;
};

export const modalInitialState: ModalState = {
  modalProps: { title: '', description: '', isOpen: false },
};

export default ModalState;

import { Action } from 'redux';
import { all, call, put, race, take, takeEvery } from 'redux-saga/effects';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import entityLockAction from '~/legacy-ui/packages/lock/state/action/lock/entityLockAction';
import entityUnlockAction from '~/legacy-ui/packages/lock/state/action/unlock/entityUnlockAction';
import { pageReloadRequestedAction } from '~/wm-legacy/packages/page-reload/pageReloadActions';
import apiErrorAction, { ErrorActionType } from '~/wm/packages/api/packages/api-error/state/apiErrorAction';
import { ApiResult } from '~/wm/packages/api/packages/api-result/callApiOrError';
import ProgressResponse from '../model/ProgressResponse';
import alertProgressWatcher from '../packages/alert/io/alertProgressWatcher';
import progressReceivedAction from '../state/action/received/progressReceivedAction';
import progressRequestedAction, { ProgressRequested, progressRequestedKey } from '../state/action/requested/progressRequestedAction';
import { ProgressStatus, progressStatusKey } from '../state/action/status/progressStatusAction';
import progressStoppedAction, { ProgressStopped, progressStoppedKey } from '../state/action/stopped/progressStoppedAction';
import pollingWorker from './pollingWorker';
import { v4 as uuidv4 } from 'uuid';

/**
 * - Continually fetch updates
 * - Handle reception of progress and dispatch
 *   actions as appropriate
 */
function* progressWorker(progressKey: string, { payload: { progressLabel, fetchProgress, entitiesToLock } }: ProgressRequested) {
  yield pollingWorker(function* () {
    const apiResult = (yield call(fetchProgress)) as ApiResult<ProgressResponse>;
    if (apiResult.type === 'error') {
      // Error fetching progress
      yield put(apiErrorAction(apiResult.data.type));
      return;
    }

    const progressResponse = apiResult.data;

    switch (progressResponse.type) {
      case 'starting':
        break;
      case 'in-progress':
        if (typeof entitiesToLock !== 'undefined') {
          yield put(entityLockAction({ entities: entitiesToLock }));
        }
        yield put(
          progressReceivedAction({
            progressKey,
            progressLabel,
            progress: progressResponse.progress,
          }),
        );
        break;
      case 'done':
        yield put(progressStoppedAction({ progressKey }));
        break;
      default:
        assertNeverOrThrow(progressResponse);
    }
  });
}

/**
 * Listens to the progress requested action
 * and when triggered initiates a progress workflow.
 *
 * Continues until progress stopped action has been received.
 */
function* progressWatcher() {
  yield all([
    takeEvery(progressStatusKey, function* ({ payload }: ProgressStatus) {
      // Check the status of the process
      const apiResult: ApiResult<ProgressResponse> = yield call(payload.fetchProgress);
      if (apiResult.type === 'error') {
        // Error fetching progress
        yield put(apiErrorAction(apiResult.data.type));
        return;
      }
      const progressResponse = apiResult.data;

      const isInProgress = (() => {
        switch (progressResponse.type) {
          case 'starting':
            return true;
          case 'in-progress':
            return true;
          case 'done':
            return false;
        }
      })();

      if (isInProgress) {
        // in progress, start progress polling
        yield put(progressRequestedAction(payload));
        return;
      }

      // no progress, release locks
      if (typeof payload.entitiesToLock !== 'undefined') {
        yield put(entityUnlockAction({ entities: payload.entitiesToLock }));
      }
    }),
    takeEvery(progressRequestedKey, function* (action: ProgressRequested) {
      const progressKey = uuidv4();

      yield race([
        take(ErrorActionType.ApiError),

        call(function* () {
          yield race([
            call(progressWorker, progressKey, action),

            // As polling is an infinite worker, it's
            // reception of the stopped action
            // that completes the watcher
            take((action: Action) => action.type === progressStoppedKey && (action as ProgressStopped).payload.progressKey === progressKey),
          ]);
          yield call(function* () {
            if (action.payload.onCompletion) {
              if (action.payload.onCompletion === 'reload-page') {
                yield put(pageReloadRequestedAction());
              } else {
                action.payload.onCompletion();
              }
            }
          });

          if (
            typeof action.payload.entitiesToLock !== 'undefined' &&
            // if reloading, don't release lock - let service-page reload
            action.payload.onCompletion !== 'reload-page'
          ) {
            yield put(entityUnlockAction({ entities: action.payload.entitiesToLock }));
          }
        }),
      ]);
    }),
    // Register alert progress watcher
    alertProgressWatcher(),
  ]);
}

export default progressWatcher;

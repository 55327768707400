import { useEffect, Dispatch, SetStateAction } from 'react';

const useTriggerRegistrar = (
  clickHandler: (e: MouseEvent) => void | Promise<void>,
  setTriggerElement: Dispatch<SetStateAction<HTMLButtonElement | null>>,
) => {
  useEffect(() => {
    const element = document.getElementById('app-switcher-btn') as HTMLButtonElement;

    if (!element) {
      return;
    }

    element.addEventListener('click', clickHandler);
    setTriggerElement(element);

    return () => {
      element.removeEventListener('click', clickHandler);
    };
  }, [clickHandler, setTriggerElement]);
};

export default useTriggerRegistrar;

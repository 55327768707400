import React, { createContext, useEffect, useRef, useState } from 'react';
import useScalePadUid from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/useScalePadUid';
import useApi from '~/wm/packages/api/hook/useApi';
import { productNavigationGet } from '@SubscriptionClient/SubscriptionClientPublic.gen';
import { v4 as uuidv4 } from 'uuid';
import { ProductNavigationDto, ScalePadProductsNavigationDto } from '@SubscriptionClient/BeastClient/Beast/Identity/Dto/Model.gen';

export type ProductDto = ProductNavigationDto;
export type ProductsDto = ScalePadProductsNavigationDto;

export type ScalePadProductsContextType = { products: ProductsDto | undefined };

const ScalePadProductsContext = createContext<ScalePadProductsContextType>({
  products: undefined,
});

export const ScalePadProductsProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { identity } = useScalePadUid();
  const { callApi } = useApi();
  const [products, setProducts] = useState<ProductsDto>();
  const fetchTokenRef = useRef<string>();

  useEffect(() => {
    (async () => {
      fetchTokenRef.current = uuidv4();
      const fetchToken = fetchTokenRef.current;
      const response = await callApi(() =>
        productNavigationGet({
          accountUid: identity?.scalepadAccountId ?? undefined,
        }),
      );

      if (!response) {
        return;
      }

      if (fetchTokenRef.current !== fetchToken) {
        // stale api call
        return;
      }

      // Prefer non-external apps in sort order
      // This makes the grouping look more natural
      response.ownedProducts = response.ownedProducts.sort((a, b) => (a.isExternalApp === b.isExternalApp ? 0 : a.isExternalApp ? 1 : -1));

      setProducts(response);
    })();
  }, [callApi, identity]);

  return <ScalePadProductsContext.Provider value={{ products }}>{children}</ScalePadProductsContext.Provider>;
};

export default ScalePadProductsContext;

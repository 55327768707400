import { useEffect, useRef } from 'react';

/**
 * Given CSS text, inject it into the DOM to apply it globally.
 *
 * While generic, this is unlikely to be needed in other contexts, hence why it's
 * folderized here.
 */
const useGlobalStyleInjection = (css: string) => {
  const styleElementRef = useRef(document.createElement('style'));

  useEffect(() => {
    const styleElement = styleElementRef.current;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    styleElementRef.current.innerHTML = css;
  }, [css]);
};

export default useGlobalStyleInjection;

import useScalePadOrigin from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/useScalePadOrigin';
import { FC } from 'react';

export type ImageProps = {
  src: string;
  className?: string;
};

const AppSwitcherImage: FC<ImageProps> = ({ src, className }) => {
  const { forceAbsolutePath } = useScalePadOrigin();

  return (
    <img
      className={className ? className : ''}
      src={forceAbsolutePath(src)}
    />
  );
};

export default AppSwitcherImage;
